import { useState } from 'react';
import { ServiceEvents } from '../../packages/generic-fetch/types';
import { ContractsFetchRepository } from '../../app/services/ContractsFetchRepository';
import { ContractCreateForm, ContractUpdateForm } from '../../app/models/Contract';
import { DocumentContract } from '../../app/models/DocumentContract';

const useContractsService = () => {
    const repository = new ContractsFetchRepository();

    const [fetchingGetContracts, setFetchingGetContracts] = useState(false);
    const [fetchingGetContractPayments, setFetchingGetContractPayments] = useState(false);
    const [fetchingGetContractsPayments, setFetchingGetContractsPayments] = useState(false);
    const [fetchingGetContractDocuments, setFetchingGetContractDocuments] = useState(false);
    const [fetchingGetContractHistories, setFetchingGetContractHistories] = useState(false);
    const [fetchingGetContractPDF, setFetchingGetContractPDF] = useState(false);
    const [fetchingGetContractExcel, setFetchingGetContractExcel] = useState(false);
    const [fetchingCreateContract, setFetchingCreateContract] = useState(false);
    const [fetchingStoreContract, setFetchingStoreContract] = useState(false);
    const [fetchingEditContract, setFetchingEditContract] = useState(false);
    const [fetchingUpdateContract, setFetchingUpdateContract] = useState(false);
    const [fetchingDeleteContract, setFetchingDeleteContract] = useState(false);
    const [fetchingSyncContractServices, setFetchingSyncContractServices] = useState(false);
    const [fetchingUpdateContractDocuments, setFetchingUpdateContractDocuments] = useState(false);
    const [fetchingGetContractPaymentsPDF, setFetchingGetContractPaymentsPDF] = useState(false);
    const [fetchingGetContractPaymentsExcel, setFetchingGetContractPaymentsExcel] = useState(false);
    const [fetchingGetContractsPaymentsExcel, setFetchingGetContractsPaymentsExcel] = useState(false);
    const [fetchingGetContractsPaymentsPDF, setFetchingGetContractsPaymentsPDF] = useState(false);
    const getContracts = async (
        currency: string,
        period: string,
        year: string,
        costCenterSelected: any[],
        events: ServiceEvents = {}
    ) => {
        setFetchingGetContracts(true);
        try {
            return repository
                .all(currency, period, year, costCenterSelected, events)
                .finally(() => {
                    setFetchingGetContracts(false);
                });
        } catch (error) {
            setFetchingGetContracts(false);
        }
    };

    const getContractPayments = async (
        id: number,
        period: string,
        currency: string,
        events: ServiceEvents = {}
    ) => {
        setFetchingGetContractPayments(true);
        try {
            return repository.allContractPayments(id, period, currency, events).finally(() => {
                setFetchingGetContractPayments(false);
            });
        } catch (error) {
            setFetchingGetContractPayments(false);
        }
    };

    const getContractPaymentsPDF = async (
        id: number,
        period: string,
        currency: string,
        events: ServiceEvents = {}
    ) => {
        setFetchingGetContractPaymentsPDF(true);
        try {
            return repository.pdfContractPayments(id, period, currency, events).finally(() => {
                setFetchingGetContractPaymentsPDF(false);
            });
        } catch (error) {
            setFetchingGetContractPaymentsPDF(false);
        }
    };

    const getContractPaymentsExcel = async (
        id: number,
        period: string,
        currency: string,
        events: ServiceEvents = {}
    ) => {
        setFetchingGetContractPaymentsExcel(true);
        try {
            return repository.excelContractPayments(id, period, currency, events).finally(() => {
                setFetchingGetContractPaymentsExcel(false);
            });
        } catch (error) {
            setFetchingGetContractPaymentsExcel(false);
        }
    };

    const getContractsPayments = async (
        period: string,
        currency: string,
        events: ServiceEvents = {}
    ) => {
        setFetchingGetContractsPayments(true);
        try {
            return repository.allContractsPayments(period, currency, events).finally(() => {
                setFetchingGetContractsPayments(false);
            });
        } catch (error) {
            setFetchingGetContractsPayments(false);
        }
    };

    const getContractsPaymentsExcel = async (
        period: string,
        currency: string,
        events: ServiceEvents = {}
    ) => {
        setFetchingGetContractsPaymentsExcel(true);
        try {
            return repository.excelContractsPayments(period, currency, events).finally(() => {
                setFetchingGetContractsPaymentsExcel(false);
            });
        } catch (error) {
            setFetchingGetContractsPaymentsExcel(false);
        }
    };

    const getContractsPaymentsPDF = async (
        period: string,
        currency: string,
        events: ServiceEvents = {}
    ) => {
        setFetchingGetContractsPaymentsPDF(true);
        try {
            return repository.pdfContractsPayments(period, currency, events).finally(() => {
                setFetchingGetContractsPaymentsPDF(false);
            });
        } catch (error) {
            setFetchingGetContractsPaymentsPDF(false);
        }
    };

    const getContractDocuments = async (id: number, events: ServiceEvents = {}) => {
        setFetchingGetContractDocuments(true);
        try {
            return repository.allDocuments(id, events).finally(() => {
                setFetchingGetContractDocuments(false);
            });
        } catch (error) {
            setFetchingGetContractDocuments(false);
        }
    };

    const getContractHistories = async (id: number, events: ServiceEvents = {}) => {
        setFetchingGetContractHistories(true);
        try {
            return repository.allHistory(id, events).finally(() => {
                setFetchingGetContractHistories(false);
            });
        } catch (error) {
            setFetchingGetContractHistories(false);
        }
    };

    const getContractsPDF = async (
        currency: string,
        period: string,
        year: string,
        costCenterSelected: any[],
        events: ServiceEvents = {}
    ) => {
        setFetchingGetContractPDF(true);
        try {
            return repository
                .pdf(currency, period, year, costCenterSelected, events)
                .finally(() => {
                    setFetchingGetContractPDF(false);
                });
        } catch (error) {
            setFetchingGetContractPDF(false);
        }
    };

    const getContractsExcel = async (
        currency: string,
        period: string,
        year: string,
        costCenterSelected: any[],
        events: ServiceEvents = {}
    ) => {
        setFetchingGetContractExcel(true);
        try {
            return repository
                .excel(currency, period, year, costCenterSelected, events)
                .finally(() => {
                    setFetchingGetContractExcel(false);
                });
        } catch (error) {
            setFetchingGetContractExcel(false);
        }
    };

    const createContract = async (events: ServiceEvents = {}) => {
        setFetchingCreateContract(true);
        try {
            return repository.create(events).finally(() => {
                setFetchingCreateContract(false);
            });
        } catch (error) {
            setFetchingCreateContract(false);
        }
    };

    const storeContract = async (contract: ContractCreateForm, events: ServiceEvents = {}) => {
        setFetchingStoreContract(true);
        try {
            return repository.store(contract, events).finally(() => {
                setFetchingStoreContract(false);
            });
        } catch (error) {
            setFetchingStoreContract(false);
        }
    };

    const editContract = async (id: number, events: ServiceEvents = {}) => {
        setFetchingEditContract(true);
        try {
            return repository.edit(id, events).finally(() => {
                setFetchingEditContract(false);
            });
        } catch (error) {
            setFetchingEditContract(false);
        }
    };

    const getContractHistory = async (id: number, events: ServiceEvents = {}) => {
        setFetchingEditContract(true);
        try {
            return repository.history(id, events).finally(() => {
                setFetchingEditContract(false);
            });
        } catch (error) {
            setFetchingEditContract(false);
        }
    };

    const updateContract = async (
        id: number,
        contract: ContractUpdateForm,
        events: ServiceEvents = {}
    ) => {
        setFetchingUpdateContract(true);
        try {
            return repository.update(id, contract, events).finally(() => {
                setFetchingUpdateContract(false);
            });
        } catch (error) {
            setFetchingUpdateContract(false);
        }
    };

    const deleteContract = async (id: number, events: ServiceEvents = {}) => {
        setFetchingDeleteContract(true);
        try {
            return repository.delete(id, events).finally(() => {
                setFetchingDeleteContract(false);
            });
        } catch (error) {
            setFetchingDeleteContract(false);
        }
    };

    const updateContractDocuments = async (
        id: number,
        contractDocuments: DocumentContract[],
        events: ServiceEvents = {}
    ) => {
        setFetchingUpdateContractDocuments(true);
        try {
            return repository.updateDocument(id, contractDocuments, events).finally(() => {
                setFetchingUpdateContractDocuments(false);
            });
        } catch (error) {
            setFetchingUpdateContractDocuments(false);
        }
    };

    const syncContractServices = async (
        id: number,
        services: number[],
        events: ServiceEvents = {}
    ) => {
        setFetchingSyncContractServices(true);
        try {
            return repository.syncServices(id, services, events).finally(() => {
                setFetchingSyncContractServices(false);
            });
        } catch (error) {
            setFetchingSyncContractServices(false);
        }
    };

    const getTemporayUrl = async (id?: number) => {
        try {
            return repository.temporaryUrl(id);
        } catch (error) {
            console.log(error);
        }
    };


    return {
        fetchingGetContracts,
        getContracts,
        fetchingGetContractPDF,
        getContractsPDF,
        fetchingGetContractExcel,
        getContractsExcel,
        fetchingCreateContract,
        createContract,
        fetchingStoreContract,
        storeContract,
        fetchingEditContract,
        editContract,
        fetchingUpdateContract,
        updateContract,
        fetchingDeleteContract,
        deleteContract,
        fetchingSyncContractServices,
        syncContractServices,
        getTemporayUrl,
        fetchingGetContractDocuments,
        getContractDocuments,
        updateContractDocuments,
        fetchingUpdateContractDocuments,
        fetchingGetContractHistories,
        getContractHistories,
        getContractHistory,
        fetchingGetContractPayments,
        getContractPayments,
        getContractsPayments,
        fetchingGetContractsPayments,
        getContractPaymentsPDF,
        getContractPaymentsExcel,
        fetchingGetContractPaymentsPDF,
        fetchingGetContractPaymentsExcel,
        getContractsPaymentsExcel,
        getContractsPaymentsPDF,
        fetchingGetContractsPaymentsPDF,
        fetchingGetContractsPaymentsExcel
    };
};

export default useContractsService;
