import React from 'react';
import Template from './template';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PageNotFound from './template/layouts/PageNotFound';
import Dashboard from './pages/dashboard';
import LoginPage from './pages/auth/Login';
import RegisterPage from './pages/auth/Register';
import ConfirmEmailPage from './pages/auth/ConfirmEmail';
import PrivateRouteMiddleware from './routes/private-middleware';
import PublicRouteMiddleware from './routes/public-middleware';
import TemplateProvider from './template/contexts/template';
import AuthProvider from './template/contexts/auth';
import ForgotPasswordPage from './pages/auth/ForgotPassword';
import ResetPasswordPage from './pages/auth/ResetPassword';
import ContractsPage from './pages/contracts/pages/ContractsPage';
import ProvidersPage from './pages/providers/pages/ProvidersPage';
import ContractEditPage from './pages/contracts/pages/ContractEditPage';
import CostCentersPage from './pages/cost_centers/pages/CostCentersPage';
import CostCenterPaymentsPage from './pages/cost_centers/pages/CostCenterPaymentsPage';
import ContractPaymentsPage from './pages/contracts/pages/ContractPaymentsPage';
import ContractPaymentServicesPage from './pages/contracts/pages/ContractPaymentServicesPage';
import ContractCreatePage from './pages/contracts/pages/ContracCreatePage';
import ContractHistoryPage from './pages/contracts/pages/ContracHistoryPage';
import UsersPage from './pages/users/pages/UsersPage';
import LessorsPage from './pages/lessors/pages/LessorsPage';
import ContractsPaymentsPage from './pages/contracts/pages/ContractsPaymentsPage';

const App = () => {
    //add to body data-sidebar="dark" data-layout-mode="light"

    // const body = document.getElementsByTagName('body')[0];
    // body.setAttribute('data-sidebar', 'dark');
    // body.setAttribute('data-layout-mode', 'light');

    return (
        <AuthProvider>
            <TemplateProvider>
                <Router>
                    <Switch>
                        {/*<Route path="/unauthorized" component={PageUnauthorized} />*/}

                        <PublicRouteMiddleware exact path="/confirm/:token?">
                            <Template withWrapper={false}>
                                <ConfirmEmailPage />
                            </Template>
                        </PublicRouteMiddleware>
                        <PublicRouteMiddleware exact path="/reset-password">
                            <Template withWrapper={false}>
                                <ResetPasswordPage />
                            </Template>
                        </PublicRouteMiddleware>
                        <PublicRouteMiddleware exact path="/forgot-password">
                            <Template withWrapper={false}>
                                <ForgotPasswordPage />
                            </Template>
                        </PublicRouteMiddleware>
                        <PublicRouteMiddleware exact path="/login">
                            <Template withWrapper={false}>
                                <LoginPage />
                            </Template>
                        </PublicRouteMiddleware>
                        {/*<PublicRouteMiddleware exact path="/register">*/}
                        {/*    <Template withWrapper={false}>*/}
                        {/*        <RegisterPage />*/}
                        {/*    </Template>*/}
                        {/*</PublicRouteMiddleware>*/}

                        {/*<PrivateRouteMiddleware*/}
                        {/*    exact*/}
                        {/*    path="/contracts/:contract_id/contract-payments"*/}
                        {/*>*/}
                        {/*    <Template>*/}
                        {/*        <ContractPaymentsPage />*/}
                        {/*    </Template>*/}
                        {/*</PrivateRouteMiddleware>*/}
                        {/*<PrivateRouteMiddleware*/}
                        {/*    exact*/}
                        {/*    path="/contracts/:contract_id/contract-payments/:contract_payment_id/contract-payment-services"*/}
                        {/*>*/}
                        {/*    <Template>*/}
                        {/*        <ContractPaymentServicesPage />*/}
                        {/*    </Template>*/}
                        {/*</PrivateRouteMiddleware>*/}
                        <PrivateRouteMiddleware exact path="/contracts/:contract_id/edit">
                            <Template>
                                <ContractEditPage />
                            </Template>
                        </PrivateRouteMiddleware>
                        <PrivateRouteMiddleware exact path="/contracts/:app_log_id/history">
                            <Template>
                                <ContractHistoryPage />
                            </Template>
                        </PrivateRouteMiddleware>
                        <PrivateRouteMiddleware exact path="/contracts/create">
                            <Template>
                                <ContractCreatePage />
                            </Template>
                        </PrivateRouteMiddleware>
                        <PrivateRouteMiddleware exact path="/contracts">
                            <Template>
                                <ContractsPage />
                            </Template>
                        </PrivateRouteMiddleware>
                        <PrivateRouteMiddleware exact path="/contracts/:contract_id/payments">
                            <Template>
                                <ContractPaymentsPage />
                            </Template>
                        </PrivateRouteMiddleware>
                        <PrivateRouteMiddleware exact path="/contracts-payments">
                            <Template>
                                <ContractsPaymentsPage />
                            </Template>
                        </PrivateRouteMiddleware>
                        <PrivateRouteMiddleware exact path="/providers">
                            <Template>
                                <ProvidersPage />
                            </Template>
                        </PrivateRouteMiddleware>
                        <PrivateRouteMiddleware exact path="/lessors">
                            <Template>
                                <LessorsPage />
                            </Template>
                        </PrivateRouteMiddleware>
                        <PrivateRouteMiddleware exact path="/users">
                            <Template>
                                <UsersPage />
                            </Template>
                        </PrivateRouteMiddleware>

                        <PrivateRouteMiddleware exact path="/cost_centers">
                            <Template>
                                <CostCentersPage />
                            </Template>
                        </PrivateRouteMiddleware>
                        <PrivateRouteMiddleware exact path="/cost_centers/:cost_center_id/payments">
                            <Template>
                                <CostCenterPaymentsPage />
                            </Template>
                        </PrivateRouteMiddleware>

                        <PrivateRouteMiddleware exact path="/">
                            <Template>
                                <Dashboard />
                            </Template>
                        </PrivateRouteMiddleware>

                        <Route path="*" component={PageNotFound} />
                    </Switch>
                </Router>
            </TemplateProvider>
        </AuthProvider>
    );
};

export default App;
