import React, { useEffect, useState } from 'react';
import DefaultCard from '../../../components/default/DefaultCard';
import Breadcrumbs from '../../../template/layouts/components/Breadcrumbs';
import DefaultTable from '../../../components/default/DefaultTable';
import moment from 'moment/moment';
import useContractsService from '../../../hooks/services/useContractsService';
import DefaultToolBar from '../../../components/default/DefaultToolBar';
import FieldNumber from '../../../components/fields/FieldNumber';
import { Contract } from '../../../app/models/Contract';
import { formatAmount } from '../utils';
import { RuleRent } from '../../../app/models/RuleRent';

const ContractsPaymentsPage = () => {
    const breadcrumbs = [
        {
            name: 'Pagos del contrato',
            url: `/contracts-payments`,
            isActive: true
        }
    ];

    const {
        getContractsPayments,
        fetchingGetContractsPayments,
        getContractsPaymentsPDF,
        getContractsPaymentsExcel
    } = useContractsService();

    const [currency, setCurrency] = useState<string>('UF');
    const [month, setMonth] = useState<string>(moment().format('YYYY-MM'));

    const [contractsPayments, setContractsPayments] = useState<any>([]);

    useEffect(() => {
        init();
    }, [currency, month]);

    const init = () => {
        getContractsPayments(month, currency, {
            onSuccess: (response) => {
                setContractsPayments(response.data.contracts ?? []);
            }
        }).then();
    };

    const downloadPDF = () => {
        getContractsPaymentsPDF(month, currency, {
            onSuccess: (response) => {
                const blob = response.data;
                const a = document.createElement('a');
                const url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = 'Pagos de Contratos.pdf';
                a.click();
                window.URL.revokeObjectURL(url);
            }
        }).then();
    };

    const downloadExcel = () => {
        getContractsPaymentsExcel(month, currency, {
            onSuccess: (response) => {
                const blob = response.data;
                const a = document.createElement('a');
                const url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = 'Pagos de Contratos.xlsx';
                a.click();
                window.URL.revokeObjectURL(url);
            }
        }).then();
    };
    return (
        <>
            <Breadcrumbs pageSection={`Pagos de Contratos`} breadcrumbs={breadcrumbs} />
            <DefaultCard>
                <DefaultToolBar
                    left={
                        <>
                            <div className="row">
                                <div className="col-md-10 mt-4">
                                    <button
                                        type="button"
                                        className={`waves-effect btn btn-smm btn-danger me-4`}
                                        onClick={() => downloadPDF()}
                                    >
                                        <i className="fa fa-file-pdf" /> Descargar PDF
                                    </button>
                                    <button
                                        type="button"
                                        className={`waves-effect btn btn-smm btn-success`}
                                        onClick={() => downloadExcel()}
                                    >
                                        <i className="fa fa-file-excel" /> Descargar Excel
                                    </button>
                                </div>

                                <div className="col-md">
                                    <label className="mb-1" htmlFor="year">
                                        Filtrar por mes pago
                                    </label>

                                    <div className="col-auto">
                                        <input
                                            type="month"
                                            className="form-control"
                                            value={month}
                                            onChange={(e) => setMonth(e.target.value)}
                                        />
                                    </div>
                                </div>
                                {/*<div className="col-md-3">*/}
                                {/*    <label className="mb-1" htmlFor="currency">*/}
                                {/*        Moneda*/}
                                {/*    </label>*/}
                                {/*    <FieldSelect*/}
                                {/*        name={'currency'}*/}
                                {/*        value={currency}*/}
                                {/*        onChange={(e) => setCurrency(e.target.value)}*/}
                                {/*        options={[*/}
                                {/*            { value: 'UF', label: 'UF' },*/}
                                {/*            { value: 'CLP', label: 'CLP' }*/}
                                {/*        ]}*/}
                                {/*    />*/}
                                {/*</div>*/}
                            </div>
                        </>
                    }
                />
                <DefaultTable
                    data={contractsPayments ?? []}
                    tableLoaded={fetchingGetContractsPayments}
                    pagination={false}
                    columns={[
                        {
                            classes: '',
                            headerClasses: '',
                            dataField: 'cost_center',
                            text: 'Centro de costo',
                            formatter: (cell: any, row: any) => {
                                return cell.cost_code_id + ' - ' + cell.name;
                            }
                        },
                        {
                            classes: '',
                            headerClasses: '',
                            dataField: 'lessor',
                            text: 'Arrendador',
                            formatter: (cell: any, row: any) => {
                                return cell.id_number +' | '+ cell.business_name;
                            }
                        },
                        {
                            classes: 'text-end',
                            headerClasses: '',
                            dataField: 'active_rule_rent_amount',
                            text: 'CANON',
                            formatter: (cell: any, row: any) => {
                                if (!cell) {
                                    return 'N/A';
                                }
                                const amount = cell ?? 0;
                                return formatAmount(amount, row.active_rule_rent_currency);
                            }
                        },
                        {
                            classes: '',
                            headerClasses: '',
                            dataField: '',
                            text: 'Estacionamiento',
                            formatter: (cell: RuleRent, row: Contract) => {
                                const otherExpenses = row.other_expenses?.find(
                                    (w) => w.name.toLowerCase() === 'estacionamiento'
                                );
                                if (otherExpenses) {
                                    return formatAmount(
                                        Number(otherExpenses.amount),
                                        otherExpenses.currency
                                    );
                                }
                                return 'N/A';
                            }
                        },
                        {
                            classes: '',
                            headerClasses: '',
                            dataField: '',
                            text: 'Gastos Comunes',
                            formatter: (cell: RuleRent, row: Contract) => {
                                const otherExpenses = row.other_expenses?.find(
                                    (w) => w.name.toLowerCase() === 'gastos comunes'
                                );
                                if (otherExpenses) {
                                    return formatAmount(
                                        Number(otherExpenses.amount),
                                        otherExpenses.currency
                                    );
                                }
                                return 'N/A';
                            }
                        },
                        {
                            classes: 'text-end',
                            headerClasses: '',
                            dataField: 'rent_rule_is_subject_to_vat',
                            text: 'Afecto a IVA',
                            formatter: (cell: any, row: any) => {
                                return cell ? 'SI' : 'NO';
                            }
                        },
                        {
                            classes: 'text-end',
                            headerClasses: '',
                            dataField: 'active_rule_monthly_sale_percentage',
                            text: 'Porcentaje Variable',
                            formatter: (cell: any, row: any) => {
                                if (!cell) {
                                    return 'N/A';
                                }
                                return `${cell}%`;
                            }
                        },
                        {
                            classes: 'text-end',
                            headerClasses: '',
                            dataField: 'total_sale',
                            text: 'Total Ventas',
                            formatter: (cell: string, row: any) => {
                                if (!cell) {
                                    return 'N/A';
                                }
                                const amount = cell ?? 0;
                                return formatAmount(
                                    Number(amount),
                                    row.active_rule_monthly_sale_currency
                                );
                            }
                        },
                        {
                            classes: 'text-end',
                            headerClasses: '',
                            dataField: 'active_rule_monthly_sale_amount',
                            text: 'Variable',
                            formatter: (cell: any, row: any) => {
                                if (!cell) {
                                    return 'N/A';
                                }
                                const amount = cell ?? 0;

                                // if (!row.monthly_sales_rule_is_activated) {
                                //     return 'N/A';
                                // }
                                return formatAmount(
                                    amount * (row.active_rule_monthly_sale_percentage / 100),
                                    row.active_rule_monthly_sale_currency
                                );
                            }
                        },
                        {
                            classes: '',
                            headerClasses: '',
                            dataField: 'active_rule_monthly_type',
                            text: 'Tipo de Venta',
                            formatter: (cell: string, row: Contract) => {
                                if (cell === 'total_amount') {
                                    return 'Venta Neta total';
                                }
                                if (cell === 'total_amount_without_aggregator') {
                                    return 'Venta Neta Sin Agregadores';
                                }
                                return 'N/A';
                            }
                        }
                    ]}
                />
            </DefaultCard>
        </>
    );
};

export default ContractsPaymentsPage;
