import React, { useEffect, useState } from 'react';
import DefaultCard from '../../../components/default/DefaultCard';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '../../../template/layouts/components/Breadcrumbs';
import DefaultTable from '../../../components/default/DefaultTable';
import moment from 'moment/moment';
import useContractsService from '../../../hooks/services/useContractsService';
import DefaultToolBar from '../../../components/default/DefaultToolBar';
import FieldNumber from '../../../components/fields/FieldNumber';
import { RuleRent } from '../../../app/models/RuleRent';
import { Contract } from '../../../app/models/Contract';
import { RuleMonthlySale } from '../../../app/models/RuleMonthlySale';
import FieldSelect from '../../../components/fields/FieldSelect';
import { formatAmount } from '../utils';
import { OtherExpense } from '../../../app/models/OtherExpense';

type Params = {
    contract_id: string;
};

const ContractPaymentsPage = () => {
    const params = useParams<Params>();

    const breadcrumbs = [
        {
            name: 'Contratos',
            url: '/contracts',
            isActive: false
        },
        {
            name: 'Pagos del contrato',
            url: `/contracts/${params.contract_id}/contract-payments`,
            isActive: true
        }
    ];

    const {
        getContractPayments,
        fetchingGetContractPayments,
        getContractPaymentsPDF,
        getContractPaymentsExcel
    } = useContractsService();

    const [year, setYear] = useState<string>(moment().format('YYYY'));
    const [currency, setCurrency] = useState<string>('UF');

    const [contractPayments, setContractPayments] = useState<any>([]);

    useEffect(() => {
        init();
    }, [currency, year]);

    const init = () => {
        getContractPayments(Number(params.contract_id), year, currency, {
            onSuccess: (response) => {
                setContractPayments(response.data.contract ?? []);
            }
        }).then();
    };

    const downloadPDF = () => {
        getContractPaymentsPDF(Number(params.contract_id), year, currency, {
            onSuccess: (response) => {
                const blob = response.data;
                const a = document.createElement('a');
                const url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = 'pagos_contratos.pdf';
                a.click();
                window.URL.revokeObjectURL(url);
            }
        }).then();
    };

    const downloadExcel = () => {
        getContractPaymentsExcel(Number(params.contract_id), year, currency, {
            onSuccess: (response) => {
                const blob = response.data;
                const a = document.createElement('a');
                const url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = 'pagos_contratos.xlsx';
                a.click();
                window.URL.revokeObjectURL(url);
            }
        }).then();
    };
    return (
        <>
            <Breadcrumbs
                pageSection={`Gestión de Pagos de Contrato - ${
                    contractPayments.cost_center ? contractPayments.cost_center.cost_code_id : ''
                }`}
                breadcrumbs={breadcrumbs}
            />
            <DefaultCard>
                <DefaultToolBar
                    left={
                        <>
                            <div className="row">
                                <div className="col-md-10 mt-4">
                                    <button
                                        type="button"
                                        className={`waves-effect btn btn-smm btn-danger me-4`}
                                        onClick={() => downloadPDF()}
                                    >
                                        <i className="fa fa-file-pdf" /> Descargar PDF
                                    </button>
                                    <button
                                        type="button"
                                        className={`waves-effect btn btn-smm btn-success`}
                                        onClick={() => downloadExcel()}
                                    >
                                        <i className="fa fa-file-excel" /> Descargar Excel
                                    </button>
                                </div>

                                <div className="col-md">
                                    <label className="mb-1" htmlFor="year">
                                        Año
                                    </label>

                                    <div className="col-auto">
                                        <FieldNumber
                                            name={'year'}
                                            value={Number(year)}
                                            onChange={(e) => setYear(e.target.value)}
                                        />
                                    </div>
                                </div>

                                {/*<div className="col-md-3">*/}
                                {/*    <label className="mb-1" htmlFor="currency">*/}
                                {/*        Moneda*/}
                                {/*    </label>*/}
                                {/*    <FieldSelect*/}
                                {/*        name={'currency'}*/}
                                {/*        value={currency}*/}
                                {/*        onChange={(e) => setCurrency(e.target.value)}*/}
                                {/*        options={[*/}
                                {/*            { value: 'UF', label: 'UF' },*/}
                                {/*            { value: 'CLP', label: 'CLP' }*/}
                                {/*        ]}*/}
                                {/*    />*/}
                                {/*</div>*/}
                            </div>
                        </>
                    }
                />
                <DefaultTable
                    data={contractPayments.monthly_results ?? []}
                    tableLoaded={fetchingGetContractPayments}
                    pagination={false}
                    columns={[
                        {
                            classes: '',
                            headerClasses: '',
                            dataField: 'month',
                            text: 'Mes de pago',
                            formatter: (cell: any, row: any) => {
                                return moment(cell).format('MM-YYYY');
                            }
                        },
                        {
                            classes: '',
                            headerClasses: '',
                            dataField: 'lessor',
                            text: 'Arrendador',
                            formatter: (cell: any, row: any) => {
                                return contractPayments.lessor.id_number +' | '+ contractPayments.lessor.business_name;
                            }
                        },
                        {
                            classes: 'text-end',
                            headerClasses: '',
                            dataField: 'active_rule_rent_amount',
                            text: 'CANON',
                            formatter: (cell: any, row: any) => {
                                if (!cell) {
                                    return 'N/A';
                                }
                                const amount = cell ?? 0;
                                return formatAmount(amount, row.active_rule_rent_currency);
                            }
                        },
                        {
                            classes: '',
                            headerClasses: '',
                            dataField: '',
                            text: 'Estacionamiento',
                            formatter: (cell: RuleRent, row: Contract) => {
                                const otherExpenses = contractPayments.other_expenses?.find(
                                    (w: OtherExpense) => w.name.toLowerCase() === 'estacionamiento'
                                );
                                if (otherExpenses) {
                                    return formatAmount(
                                        Number(otherExpenses.amount),
                                        otherExpenses.currency
                                    );
                                }
                                return 'N/A';
                            }
                        },
                        {
                            classes: '',
                            headerClasses: '',
                            dataField: '',
                            text: 'Gastos Comunes',
                            formatter: (cell: RuleRent, row: Contract) => {
                                const otherExpenses = contractPayments.other_expenses?.find(
                                    (w: OtherExpense) => w.name.toLowerCase() === 'gastos comunes'
                                );
                                if (otherExpenses) {
                                    return formatAmount(
                                        Number(otherExpenses.amount),
                                        otherExpenses.currency
                                    );
                                }
                                return 'N/A';
                            }
                        },
                        {
                            classes: 'text-end',
                            headerClasses: '',
                            dataField: 'rent_rule_is_subject_to_vat',
                            text: 'Afecto a IVA',
                            formatter: (cell: any, row: any) => {
                                return cell ? 'SI' : 'NO';
                            }
                        },
                        {
                            classes: 'text-end',
                            headerClasses: '',
                            dataField: 'active_rule_monthly_sale_percentage',
                            text: 'Porcentaje Variable',
                            formatter: (cell: any, row: any) => {
                                if (!cell) {
                                    return 'N/A';
                                }
                                return `${cell}%`;
                            }
                        },
                        {
                            classes: 'text-end',
                            headerClasses: '',
                            dataField: 'total_sale',
                            text: 'Total Ventas',
                            formatter: (cell: string, row: any) => {
                                if (!cell) {
                                    return 'N/A';
                                }
                                const amount = cell ?? 0;
                                return formatAmount(
                                    Number(amount),
                                    row.active_rule_monthly_sale_currency
                                );
                            }
                        },
                        {
                            classes: 'text-end',
                            headerClasses: '',
                            dataField: 'active_rule_monthly_sale_amount',
                            text: 'Variable',
                            formatter: (cell: any, row: any) => {
                                if (!cell) {
                                    return 'N/A';
                                }
                                const amount = cell ?? 0;

                                // if (!row.monthly_sales_rule_is_activated) {
                                //     return 'N/A';
                                // }
                                return formatAmount(
                                    amount * (row.active_rule_monthly_sale_percentage / 100),
                                    row.active_rule_monthly_sale_currency
                                );
                            }
                        },
                        {
                            classes: '',
                            headerClasses: '',
                            dataField: 'active_rule_monthly_type',
                            text: 'Tipo de Venta',
                            formatter: (cell: string, row: Contract) => {
                                if (cell === 'total_amount') {
                                    return 'Venta Neta total';
                                }
                                if (cell === 'total_amount_without_aggregator') {
                                    return 'Venta Neta Sin Agregadores';
                                }
                                return 'N/A';
                            }
                        }
                    ]}
                />
            </DefaultCard>
        </>
    );
};

export default ContractPaymentsPage;
