import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Breadcrumbs from '../../../template/layouts/components/Breadcrumbs';
import DefaultToolBar from '../../../components/default/DefaultToolBar';
import DefaultCard from '../../../components/default/DefaultCard';
import { CostCenter } from '../../../app/models/CostCenter';
import { Payment } from '../../../app/models/Payment';
import { data_payments } from '../data';
import DefaultTable from '../../../components/default/DefaultTable';
import { Service } from '../../../app/models/Service';
import ButtonTable from '../../../components/buttons/ButtonTable';

type Params = {
    cost_center_id: string;
};

const CostCenterPaymentsPage = () => {
    const { cost_center_id } = useParams<Params>();
    const history = useHistory();

    const [cost_center, setCostCenter] = useState<CostCenter>();
    const [payments, setPayments] = useState<Payment[]>(data_payments);

    const breadcrumbs = [
        {
            name: 'CostCenters',
            url: '/cost_centers',
            isActive: false
        },
        {
            name: 'Pagos del local ',
            url: `/cost_centers/${cost_center_id}/payments`,
            isActive: true
        }
    ];

    return (
        <>
            <Breadcrumbs pageSection="CostCenters" breadcrumbs={breadcrumbs} />
            <DefaultCard>
                <DefaultToolBar />
                <DefaultTable
                    data={payments}
                    tableLoaded={!true}
                    columns={[
                        {
                            classes: 'nowrap-cell',
                            headerClasses: 'nowrap-cell',
                            dataField: 'name',
                            text: 'Centro de Costo',
                            sort: true
                        },
                        {
                            classes: '',
                            headerClasses: 'D',
                            dataField: 'address',
                            text: 'Dirección',
                            sort: true
                        },
                        {
                            dataField: '',
                            text: 'Acciones',
                            classes: 'nowrap-cell nowrap-cell-no-min',
                            headerClasses: 'nowrap-cell nowrap-cell-no-min',
                            formatter: (cell: any, row: Service) => {
                                return (
                                    <div className="btn-group btn-group-sm">
                                        <ButtonTable
                                            onClick={() => history.push(`/cost_centers/${row.id}`)}
                                            title="Editar"
                                            icon="bx bx-edit-alt"
                                            variant="warning"
                                        />

                                        <ButtonTable
                                            onClick={() =>
                                                history.push(`/cost_centers/${row.id}/payments`)
                                            }
                                            title="Ver pagos"
                                            icon="bx bx-dollar"
                                            variant="info"
                                        />
                                    </div>
                                );
                            }
                        }
                    ]}
                />
            </DefaultCard>
        </>
    );
};

export default CostCenterPaymentsPage;
