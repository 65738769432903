import { GenericFetch } from '../../packages/generic-fetch/GenericFetch';
import { ServiceEvents, ServiceResponse } from '../../packages/generic-fetch/types';
import { ContractEndpoint } from './APIConnection';
import { ContractCreateForm, ContractRepository, ContractUpdateForm } from '../models/Contract';
import { DocumentContract } from '../models/DocumentContract';

export class ContractsFetchRepository extends GenericFetch implements ContractRepository {
    all(
        currency: string,
        period: string,
        year: string,
        costCenterSelected: string[],
        events: ServiceEvents
    ): Promise<ServiceResponse> {
        const costCenterQueryParam = costCenterSelected.join(',');
        return this.doGet({
            ...events,
            url:
                ContractEndpoint.all() +
                `?period=${period}&year=${year}&currency=${currency}&cost_centers=${costCenterQueryParam}`
        });
    }

    allContractPayments(
        id: number,
        period: string,
        currency: string,
        events: ServiceEvents
    ): Promise<ServiceResponse> {
        return this.doGet({
            ...events,
            url:
                ContractEndpoint.allContractPayments(id) +
                '?period=' +
                period +
                '&currency=' +
                currency
        });
    }

    pdfContractPayments(
        id: number,
        period: string,
        currency: string,
        events: ServiceEvents
    ): Promise<ServiceResponse> {
        return this.doGet({
            ...events,
            responseBlob: true,
            url:
                ContractEndpoint.pdfContractPayments(id) +
                '?period=' +
                period +
                '&currency=' +
                currency
        });
    }

    excelContractPayments(
        id: number,
        period: string,
        currency: string,
        events: ServiceEvents
    ): Promise<ServiceResponse> {
        return this.doGet({
            ...events,
            responseBlob: true,
            url:
                ContractEndpoint.excelContractPayments(id) +
                '?period=' +
                period +
                '&currency=' +
                currency
        });
    }

    allContractsPayments(
        period: string,
        currency: string,
        events: ServiceEvents
    ): Promise<ServiceResponse> {
        return this.doGet({
            ...events,
            url:
                ContractEndpoint.allContractsPayments() +
                '?period=' +
                period +
                '&currency=' +
                currency
        });
    }

    excelContractsPayments(
        period: string,
        currency: string,
        events: ServiceEvents
    ): Promise<ServiceResponse> {
        return this.doGet({
            ...events,
            responseBlob: true,
            url:
                ContractEndpoint.excelContractsPayments() +
                '?period=' +
                period +
                '&currency=' +
                currency
        });
    }

    pdfContractsPayments(
        period: string,
        currency: string,
        events: ServiceEvents
    ): Promise<ServiceResponse> {
        return this.doGet({
            ...events,
            responseBlob: true,
            url:
                ContractEndpoint.pdfContractsPayments() +
                '?period=' +
                period +
                '&currency=' +
                currency
        });
    }

    allDocuments(id: number, events: ServiceEvents): Promise<ServiceResponse> {
        return this.doGet({
            ...events,
            url: ContractEndpoint.allDocuments(id)
        });
    }

    allHistory(id: number, events: ServiceEvents): Promise<ServiceResponse> {
        return this.doGet({
            ...events,
            url: ContractEndpoint.allHistory(id)
        });
    }

    updateDocument(
        id: number,
        document: DocumentContract[],
        events: ServiceEvents
    ): Promise<ServiceResponse> {
        return this.doPost({
            ...events,
            url: ContractEndpoint.updateDocuments(id),
            requestType: 'multipart',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            body: document
        });
    }

    pdf(
        currency: string,
        period: string,
        year: string,
        costCenterSelected: string[],
        events: ServiceEvents
    ): Promise<ServiceResponse> {
        const costCenterQueryParam = costCenterSelected.join(',');
        return this.doGet({
            ...events,
            responseBlob: true,
            url:
                ContractEndpoint.pdf() +
                `?period=${period}&year=${year}&currency=${currency}&cost_centers=${costCenterQueryParam}`
        });
    }

    excel(
        currency: string,
        period: string,
        year: string,
        costCenterSelected: string[],
        events: ServiceEvents
    ): Promise<ServiceResponse> {
        const costCenterQueryParam = costCenterSelected.join(',');

        return this.doGet({
            ...events,
            responseBlob: true,
            url:
                ContractEndpoint.excel() +
                `?period=${period}&year=${year}&currency=${currency}&cost_centers=${costCenterQueryParam}`
        });
    }
    create(events: ServiceEvents): Promise<ServiceResponse> {
        return this.doGet({
            ...events,
            url: ContractEndpoint.create()
        });
    }

    store(contract: ContractCreateForm, events: ServiceEvents): Promise<ServiceResponse> {
        return this.doPost({
            ...events,
            url: ContractEndpoint.store(),
            body: contract
            // requestType: 'form-data',
            // headers: {
            //     'Content-Type': 'multipart/form-data',
            // }
        });
    }

    edit(id: number, events: ServiceEvents): Promise<ServiceResponse> {
        return this.doGet({
            ...events,
            url: ContractEndpoint.edit(id)
        });
    }

    history(id: number, events: ServiceEvents): Promise<ServiceResponse> {
        return this.doGet({
            ...events,
            url: ContractEndpoint.history(id)
        });
    }

    update(
        id: number,
        contract: ContractUpdateForm,
        events: ServiceEvents
    ): Promise<ServiceResponse> {
        return this.doPut({
            ...events,
            url: ContractEndpoint.update(id),
            body: contract
            // headers: {
            //     'Content-Type': 'multipart/form-data',
            // }
        });
    }

    delete(id: number, events: ServiceEvents): Promise<ServiceResponse> {
        return this.doDelete({
            ...events,
            url: ContractEndpoint.delete(id)
        });
    }

    syncServices(id: number, services: number[], events: ServiceEvents): Promise<ServiceResponse> {
        return this.doPut({
            ...events,
            url: ContractEndpoint.syncServices(id),
            body: {
                services_ids: services
            }
        });
    }

    temporaryUrl(id?: number): Promise<{ temporaryUrl: string; http_status: number }> {
        return this.doGet({
            url: ContractEndpoint.temporaryUrl(id)
        });
    }
}
