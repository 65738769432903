import React, { ChangeEvent, useEffect, useState } from 'react';
import FieldSelect, { FieldSelectOption } from '../../../components/fields/FieldSelect';
import FieldNumber from '../../../components/fields/FieldNumber';
import { Currency } from '../../../app/models/Currency';
import FieldDate from '../../../components/fields/FieldDate';
import { ExitClause } from '../../../app/models/ExitClause';
import { Bank } from '../../../app/models/Bank';
import FieldSwitch from '../../../components/fields/FieldSwitch';
import FieldTextArea from '../../../components/fields/FieldTextArea';
import { RuleMonthlySale } from '../../../app/models/RuleMonthlySale';
import ButtonTableDestroy from '../../../components/buttons/ButtonTableDestroy';
import FieldInput from '../../../components/fields/FieldInput';

type Props = {
    currencies: Currency[];
    exitClauses: ExitClause[];
    onUpdateExitClauses: (updatedExitClauses: ExitClause[]) => void;
    disabled: boolean;
    errors: any;
    reformatNumber: number;
};

const ExitClauseDataForm = ({
    onUpdateExitClauses,
    currencies,
    exitClauses,
    disabled,
    errors,
    reformatNumber
}: Props) => {
    const [localExitClauses, setLocalExitClauses] = useState(exitClauses);

    useEffect(() => {
        setLocalExitClauses(
            exitClauses.map((exitClause) => {
                if (!exitClause.amount) {
                    return exitClause;
                }
                const value = String(exitClause.amount);
                const [integerPart, decimalPart] = value.split('.');
                const formattedIntegerPart = new Intl.NumberFormat('de-DE').format(
                    Number(integerPart)
                );
                exitClause.amount = String(
                    value.split('.').length > 1
                        ? `${formattedIntegerPart},${decimalPart}`
                        : formattedIntegerPart
                );
                return exitClause;
            })
        );
    }, [reformatNumber]);

    const addExitClauses = () => {
        const newExitClauses: ExitClause = {
            amount: '',
            conditions: '',
            contract_id: 0,
            currency: '',
            end_date: '',
            id: 0,
            name: '',
            start_date: ''
        };

        const updatedExitClauses = [newExitClauses, ...localExitClauses];
        setLocalExitClauses(updatedExitClauses);
        onUpdateExitClauses(updatedExitClauses);
    };

    const handleExitClauseChange = (
        event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
        index: number,
        isUploadDataForm = true
    ) => {
        let value = event.target.value;
        const name = event.target.name;
        const updatedExitClauses = localExitClauses.map((exitClause, i) => {
            if (i === index) {
                if (name === 'amount' && value !== '') {
                    if (!/^[0-9,.]+$/.test(value)) {
                        value = value.replace(/[^0-9,.]/g, '');
                    }
                    const [integerPart, decimalPart] = value.split(',');
                    // Limpiar y formatear la parte entera
                    const cleanedIntegerPart = integerPart.replace(/\./g, '');
                    const formattedIntegerPart = new Intl.NumberFormat('de-DE').format(
                        Number(cleanedIntegerPart)
                    );

                    // Combinar las partes formateadas
                    const formattedValue =
                        value.split(',').length > 1
                            ? `${formattedIntegerPart},${decimalPart}`
                            : formattedIntegerPart;

                    return { ...exitClause, [name]: String(formattedValue) };
                }
                const target = event.target as HTMLInputElement; // type assertion
                return {
                    ...exitClause,
                    [target.name]: target.type === 'checkbox' ? target.checked : target.value
                };
            }
            return exitClause;
        });

        setLocalExitClauses(updatedExitClauses);
        if (isUploadDataForm) {
            onUpdateExitClauses(updatedExitClauses);
        }
    };

    const changeBlurred = (
        e: React.FocusEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | undefined
    ) => {
        if (e) {
            onUpdateExitClauses(localExitClauses);
        }
    };

    const handleDeleteExitClauses = (index: number) => {
        const updatedExitClauses = [...localExitClauses];
        updatedExitClauses.splice(index, 1);
        setLocalExitClauses(updatedExitClauses);
        onUpdateExitClauses(updatedExitClauses);
    };

    return (
        <>
            <div className="row mb-2">
                <div className="col-md-12">
                    {!disabled ? (
                        <button
                            type="button"
                            className="btn btn-primary float-end"
                            disabled={disabled}
                            onClick={addExitClauses}
                        >
                            Agregar Clausula de Salida
                        </button>
                    ) : null}
                </div>
            </div>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">Nombre</th>
                        <th scope="col">Fecha de inicio</th>
                        <th scope="col">Fecha de termino</th>
                        <th scope="col">Moneda</th>
                        <th scope="col">Monto</th>
                        <th scope="col">Acciones</th>
                        {/* Agrega más encabezados de columna aquí según sea necesario */}
                    </tr>
                </thead>
                <tbody>
                    {localExitClauses.map((exitClause, index) => (
                        <>
                            <tr style={{ borderBottomStyle: 'hidden' }}>
                                <td>
                                    <FieldInput
                                        name={'name'}
                                        value={exitClause.name}
                                        onChange={(e) => handleExitClauseChange(e, index, false)}
                                        onBlur={changeBlurred}
                                        disabled={disabled}
                                        errors={errors}
                                        nameFieldError={'exit_clauses.' + index + '.name'}
                                        placeholder={'Nombre'}
                                    />
                                </td>
                                {/*// agrega más campos de start_date y end_date*/}
                                <td>
                                    <FieldDate
                                        name={'start_date'}
                                        value={exitClause.start_date}
                                        onChange={(e) => handleExitClauseChange(e, index)}
                                        disabled={disabled}
                                        errors={errors}
                                        nameFieldError={'exit_clauses.' + index + '.start_date'}
                                        placeholder={'Fecha de inicio'}
                                    />
                                </td>

                                <td>
                                    <FieldDate
                                        name={'end_date'}
                                        value={exitClause.end_date}
                                        onChange={(e) => handleExitClauseChange(e, index)}
                                        disabled={disabled}
                                        nameFieldError={'exit_clauses.' + index + '.end_date'}
                                        errors={errors}
                                        placeholder={'Fecha de fin'}
                                    />
                                </td>

                                <td>
                                    <FieldSelect
                                        name={'currency'}
                                        value={exitClause.currency}
                                        errors={errors}
                                        nameFieldError={'exit_clauses.' + index + '.currency'}
                                        disabled={disabled}
                                        options={currencies.map(
                                            (currency) =>
                                                ({
                                                    value: currency.value,
                                                    label: currency.label
                                                } as FieldSelectOption)
                                        )}
                                        onChange={(event) => handleExitClauseChange(event, index)}
                                    />
                                </td>
                                <td>
                                    <FieldInput
                                        name={'amount'}
                                        value={exitClause.amount}
                                        errors={errors}
                                        nameFieldError={'exit_clauses.' + index + '.amount'}
                                        disabled={disabled}
                                        onBlur={changeBlurred}
                                        onChange={(event) =>
                                            handleExitClauseChange(event, index, false)
                                        }
                                    />
                                </td>

                                <td>
                                    <ButtonTableDestroy
                                        onClick={() => handleDeleteExitClauses(index)}
                                        locked={disabled}
                                        loading={disabled}
                                    />
                                </td>
                                {/* Renderiza otras celdas con campos de ExitClause aquí */}
                            </tr>
                            <tr style={{ marginTop: -15 }}>
                                <td colSpan={6}>
                                    <label>
                                        <b>Condiciones</b>
                                    </label>
                                    <FieldTextArea
                                        name={'conditions'}
                                        nameFieldError={'exit_clauses.' + index + '.conditions'}
                                        value={exitClause.conditions}
                                        errors={errors}
                                        disabled={disabled}
                                        onBlur={changeBlurred}
                                        onChange={(event) =>
                                            handleExitClauseChange(event, index, false)
                                        }
                                    />
                                </td>
                            </tr>
                        </>
                    ))}
                </tbody>
            </table>
        </>
    );
};

export default ExitClauseDataForm;
