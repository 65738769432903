import { CostCenter } from './CostCenter';
import { Lessor } from './Lessor';
import { Service } from './Service';
import { ServiceEvents, ServiceResponse } from '../../packages/generic-fetch/types';
import { WarrantyType } from './WarrantyType';
import { RuleRent } from './RuleRent';
import { RuleMonthlySale } from './RuleMonthlySale';
import { OtherExpense } from './OtherExpense';
import { ExitClause } from './ExitClause';
import { DocumentContract } from './DocumentContract';
import { ContractService } from './ContractService';

export type Contract = {
    id: number;
    start_date: string;
    end_date: string;
    grace_months: number;
    grace_days: number;
    remodeling_date: string;
    initial_payment_date?: Date;
    cost_center_id?: number;
    lessor_id?: number;
    warranty_types?: WarrantyType[];
    rule_rents?: RuleRent[];
    rule_monthly_sales?: RuleMonthlySale[];
    other_expenses?: OtherExpense[];
    exit_clauses?: ExitClause[];
    document_contracts?: DocumentContract[];
    contract_services?: ContractService[];
};

export type ContractCreateForm = {
    start_date: string;
    end_date: string;
    grace_months: number;
    grace_days: number;
    remodeling_date: string;
    initial_payment_date?: Date;
    cost_center_id?: number;
    lessor_id?: number;
    warranty_types?: WarrantyType[];
    rule_rents?: RuleRent[];
    rule_monthly_sales?: RuleMonthlySale[];
    other_expenses?: OtherExpense[];
    exit_clauses?: ExitClause[];
    contract_services?: ContractService[];

    document_contracts?: DocumentContract[];
};

export const initialContractCreateForm: ContractCreateForm = {
    start_date: '',
    end_date: '',
    grace_months: 0,
    grace_days: 0,
    remodeling_date: ''
};

export type ContractUpdateForm = {
    id: number;
    start_date: string;
    end_date: string;
    grace_months: number;
    grace_days: number;
    remodeling_date: string;
    initial_payment_date?: Date;

    cost_center_id?: number;
    lessor_id?: number;
    warranty_types?: WarrantyType[];
    rule_rents?: RuleRent[];
    rule_monthly_sales?: RuleMonthlySale[];
    other_expenses?: OtherExpense[];
    exit_clauses?: ExitClause[];
    document_contracts?: DocumentContract[];
    contract_services?: ContractService[];
};

export const initialContractUpdateForm: ContractUpdateForm = {
    end_date: '',
    grace_months: 0,
    grace_days: 0,
    id: 0,
    remodeling_date: '',
    start_date: ''
};

export type ContractRepository = {
    all(
        currency: string,
        period: string,
        year: string,
        costCenterSelected: any[],
        events: ServiceEvents
    ): Promise<ServiceResponse>;
    create(events: ServiceEvents): Promise<ServiceResponse>;
    store(contract: ContractCreateForm, events: ServiceEvents): Promise<ServiceResponse>;
    edit(id: number, events: ServiceEvents): Promise<ServiceResponse>;
    update(
        id: number,
        contract: ContractUpdateForm,
        events: ServiceEvents
    ): Promise<ServiceResponse>;
    delete(id: number, events: ServiceEvents): Promise<ServiceResponse>;
    syncServices(id: number, services: number[], events: ServiceEvents): Promise<ServiceResponse>;
};
