import React, { useEffect, useState } from 'react';
import DefaultCard from '../../../components/default/DefaultCard';
import { useHistory } from 'react-router-dom';
import Breadcrumbs from '../../../template/layouts/components/Breadcrumbs';
import DefaultToolBar from '../../../components/default/DefaultToolBar';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import { toast } from 'react-toastify';
import { User } from '../../../app/models/User';
import DefaultTable from '../../../components/default/DefaultTable';
import ModalCreateUser from '../components/ModalCreateUser';
import ModalEditUser from '../components/ModalEditUser';
import ButtonTableEdit from '../../../components/buttons/ButtonTableEdit';
import useSweetAlert from '../../../hooks/useSweetAlert';
import ButtonTableDestroy from '../../../components/buttons/ButtonTableDestroy';
import useUsersService from '../../../hooks/services/useUsersService';

const UsersPage = () => {
    const breadcrumbs = [
        {
            name: 'Usuarios',
            url: '/users',
            isActive: true
        }
    ];

    const { fetchingGetUsers, getUsers, fetchingDeleteUser, deleteUser } = useUsersService();

    const [users, setUsers] = useState<User[]>([]);

    const [showingCreateView, setShowingCreateView] = useState(false);
    const [showingEditView, setShowingEditView] = useState(false);
    const [idToEdit, setIdToEdit] = useState(0);

    useEffect(() => {
        init();
    }, []);

    const init = () => {
        getUsers({
            onSuccess: (response) => {
                setUsers(response.data.users);
            }
        }).then();
    };

    const showEditView = (id: number) => {
        setShowingEditView(true);
        setIdToEdit(id);
    };

    const closeEditView = () => {
        setShowingEditView(false);
        setIdToEdit(0);
    };

    const destroy = (id: number) => {
        const { requestConfirmation } = useSweetAlert();

        requestConfirmation({
            title: '¿Estás seguro?',
            text: 'No podrás revertir esta acción',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
            onConfirm: () => {
                deleteUser(id, {
                    onSuccess: () => {
                        toast.success('Usuario eliminado con éxito');
                        init();
                    },
                    onError: () => {
                        toast.error('Ocurrió un error al eliminar el usuario');
                    }
                }).then();
            }
        });
    };

    return (
        <>
            <Breadcrumbs pageSection="Gestión de Usuarios" breadcrumbs={breadcrumbs} />
            <DefaultCard>
                <DefaultToolBar
                    left={
                        <ButtonCreate
                            onClick={() => setShowingCreateView(true)}
                            title="Nuevo Usuario"
                        />
                    }
                />

                <DefaultTable
                    data={users}
                    tableLoaded={fetchingGetUsers}
                    columns={[
                        {
                            classes: 'nowrap-cell',
                            headerClasses: 'nowrap-cell',
                            dataField: 'first_name',
                            text: 'Nombre',
                            sort: true
                        },
                        {
                            classes: 'nowrap-cell',
                            headerClasses: 'nowrap-cell',
                            dataField: 'last_name',
                            text: 'Apellido',
                            sort: true
                        },
                        {
                            classes: '',
                            headerClasses: '',
                            dataField: 'email',
                            text: 'Email',
                            sort: true
                        },
                        {
                            dataField: '',
                            text: 'Acciones',
                            classes: 'nowrap-cell nowrap-cell-no-min',
                            headerClasses: 'nowrap-cell nowrap-cell-no-min',
                            formatter: (cell: any, row: User) => {
                                return (
                                    <div className="btn-group btn-group-sm">
                                        <ButtonTableEdit onClick={() => showEditView(row.id)} />
                                        <ButtonTableDestroy onClick={() => destroy(row.id)} />
                                    </div>
                                );
                            }
                        }
                    ]}
                />
            </DefaultCard>
            {showingCreateView && (
                <ModalCreateUser
                    show={showingCreateView}
                    onClose={() => setShowingCreateView(false)}
                    onSuccess={() => {
                        toast.success('Usuario creado con éxito');
                        setShowingCreateView(false);
                        init();
                    }}
                    onError={() => {
                        toast.error('Ocurrió un error al crear el usuario');
                    }}
                />
            )}

            {showingEditView && (
                <ModalEditUser
                    id={idToEdit}
                    show={showingEditView}
                    onClose={closeEditView}
                    onSuccess={() => {
                        toast.success('Usuario editado con éxito');
                        setShowingEditView(false);
                        init();
                    }}
                    onError={() => {
                        toast.error('Ocurrió un error al editar el usuario');
                    }}
                />
            )}
        </>
    );
};

export default UsersPage;
