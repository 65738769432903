import React, { useEffect, useState } from 'react';
import DefaultCard from '../../../components/default/DefaultCard';
import Breadcrumbs from '../../../template/layouts/components/Breadcrumbs';
import DefaultToolBar from '../../../components/default/DefaultToolBar';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import { toast } from 'react-toastify';
import { Lessor } from '../../../app/models/Lessor';
import DefaultTable from '../../../components/default/DefaultTable';
import ModalCreateLessor from '../components/ModalCreateLessor';
import ModalEditLessor from '../components/ModalEditLessor';
import ButtonTableEdit from '../../../components/buttons/ButtonTableEdit';
import useSweetAlert from '../../../hooks/useSweetAlert';
import ButtonTableDestroy from '../../../components/buttons/ButtonTableDestroy';
import useLessorsService from '../../../hooks/services/useLessorsService';

const LessorsPage = () => {
    const breadcrumbs = [
        {
            name: 'Arrendatarios',
            url: '/lessors',
            isActive: true
        }
    ];

    const {
        fetchingGetLessors,
        getLessors,
        fetchingDeleteLessor,
        deleteLessor,
        fetchingEditLessor
    } = useLessorsService();

    const [lessors, setLessors] = useState<Lessor[]>([]);

    const [showingCreateView, setShowingCreateView] = useState(false);
    const [showingEditView, setShowingEditView] = useState(false);
    const [idToEdit, setIdToEdit] = useState(0);

    useEffect(() => {
        init();
    }, []);

    const init = () => {
        getLessors({
            onSuccess: (response) => {
                setLessors(response.data.lessors);
            }
        }).then();
    };

    const showEditView = (id: number) => {
        setShowingEditView(true);
        setIdToEdit(id);
    };

    const closeEditView = () => {
        setShowingEditView(false);
        setIdToEdit(0);
    };

    const destroy = (id: number) => {
        const { requestConfirmation } = useSweetAlert();

        requestConfirmation({
            title: '¿Estás seguro?',
            text: 'No podrás revertir esta acción',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
            onConfirm: () => {
                deleteLessor(id, {
                    onSuccess: () => {
                        toast.success('Arrendatario eliminado con éxito');
                        init();
                    },
                    onError: () => {
                        toast.error('Ocurrió un error al eliminar el arrendatario');
                    }
                }).then();
            }
        });
    };

    return (
        <>
            <Breadcrumbs pageSection="Gestión de Arrendatarios" breadcrumbs={breadcrumbs} />
            <DefaultCard>
                <DefaultToolBar
                    left={
                        <ButtonCreate
                            onClick={() => setShowingCreateView(true)}
                            title="Nuevo Arrendatario"
                        />
                    }
                />

                <DefaultTable
                    data={lessors}
                    tableLoaded={fetchingGetLessors}
                    columns={[
                        {
                            classes: 'nowrap-cell',
                            headerClasses: 'nowrap-cell',
                            dataField: 'id_number',
                            text: 'RUT',
                            sort: true
                        },
                        {
                            classes: 'nowrap-cell',
                            headerClasses: 'nowrap-cell',
                            dataField: 'business_name',
                            text: 'Razón Social',
                            sort: true
                        },
                        {
                            classes: 'nowrap-cell',
                            headerClasses: 'nowrap-cell',
                            dataField: 'address',
                            text: 'Dirección',
                            sort: true
                        },
                        {
                            classes: 'nowrap-cell',
                            headerClasses: 'nowrap-cell',
                            dataField: 'email',
                            text: 'Email',
                            sort: true
                        },
                        {
                            classes: 'nowrap-cell',
                            headerClasses: 'nowrap-cell',
                            dataField: 'phone',
                            text: 'Teléfono',
                            sort: true
                        },
                        {
                            classes: 'nowrap-cell',
                            headerClasses: 'nowrap-cell',
                            dataField: 'phone_code',
                            text: 'Código Telefónico',
                            sort: true
                        },
                        {
                            classes: 'nowrap-cell',
                            headerClasses: 'nowrap-cell',
                            dataField: 'bank',
                            text: 'Banco',
                            sort: true,
                            formatter: (cell: any, row: Lessor) => {
                                return row.bank?.name;
                            }
                        },
                        {
                            classes: 'nowrap-cell',
                            headerClasses: 'nowrap-cell',
                            dataField: 'bank',
                            text: 'Cuenta',
                            sort: true,
                            formatter: (cell: any, row: Lessor) => {
                                return row.bank_account_type;
                            }
                        },
                        {
                            classes: 'nowrap-cell',
                            headerClasses: 'nowrap-cell',
                            dataField: 'bank_account_number',
                            text: 'Número de Cuenta Bancaria',
                            sort: true
                        },
                        {
                            dataField: '',
                            text: 'Acciones',
                            classes: 'nowrap-cell nowrap-cell-no-min',
                            headerClasses: 'nowrap-cell nowrap-cell-no-min',
                            formatter: (cell: any, row: Lessor) => {
                                return (
                                    <div className="btn-group btn-group-sm">
                                        <ButtonTableEdit onClick={() => showEditView(row.id)} />
                                        {/*<ButtonTableDestroy onClick={() => destroy(row.id)} />*/}
                                    </div>
                                );
                            }
                        }
                    ]}
                />
            </DefaultCard>
            {showingCreateView && (
                <ModalCreateLessor
                    show={showingCreateView}
                    onClose={() => setShowingCreateView(false)}
                    onSuccess={() => {
                        toast.success('Arrendatario creado con éxito');
                        setShowingCreateView(false);
                        init();
                    }}
                    onError={() => {
                        toast.error('Ocurrió un error al crear el arrendatario');
                    }}
                />
            )}

            {showingEditView && (
                <ModalEditLessor
                    id={idToEdit}
                    show={showingEditView}
                    onClose={closeEditView}
                    onSuccess={() => {
                        toast.success('Arrendatario editado con éxito');
                        setShowingEditView(false);
                        init();
                    }}
                    onError={() => {
                        toast.error('Ocurrió un error al editar el arrendatario');
                    }}
                />
            )}
        </>
    );
};

export default LessorsPage;
