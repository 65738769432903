import React, { ChangeEvent, useEffect, useState } from 'react';
import DefaultModal from '../../../components/default/DefaultModal';
import { ServiceResponse } from '../../../packages/generic-fetch/types';
import { toast } from 'react-toastify';
import useContractsService from '../../../hooks/services/useContractsService';
import { Contract } from '../../../app/models/Contract';
import moment from 'moment/moment';
import DefaultTable from '../../../components/default/DefaultTable';
import { Link, useHistory, useParams } from 'react-router-dom';

type Props = {
    contractId: number;
    showingView: boolean;
    closeView: () => void;
    onSuccess?: (response: ServiceResponse) => void;
    onError?: (response: ServiceResponse) => void;
};
type Params = {
    app_log_id: string;
};
const ModalContractHistory = ({
    contractId,
    showingView,
    closeView,
    onSuccess,
    onError
}: Props) => {
    const { getContractHistories, fetchingGetContractHistories } = useContractsService();
    const history = useHistory();
    const params = useParams<Params>();

    const [errors, setErrors] = useState<object>({});
    const [dataForm, setDataForm] = useState<[]>();

    useEffect(() => {
        init();
    }, []);

    const init = () => {
        getContractHistories(contractId, {
            onSuccess: (response) => {
                setDataForm(response.data.history);
            },
            onError: (response) => {
                toast.error('Error al cargar los datos');
                closeView();
            }
        }).then();
    };

    return (
        <DefaultModal
            title="Historial de Contrato"
            show={showingView}
            handleClose={() => {
                closeView();
            }}
            // actionButton={
            //     <ButtonSaveForm onClick={store} loading={fetchingUpdateContractHistories} />
            // }
            centered={true}
        >
            <DefaultTable
                data={dataForm ?? []}
                tableLoaded={fetchingGetContractHistories}
                columns={[
                    {
                        classes: '',
                        headerClasses: '',
                        dataField: 'created_at',
                        text: 'Fecha del registro',
                        formatter: (cell: string, row: any) => {
                            return (
                                <b
                                    className={'pointer'}
                                    onClick={() => history.push(`/contracts/${row.id}/history`)}
                                >
                                    {moment(cell).format('DD-MM-YYYY HH:mm')}
                                </b>
                            );
                        }
                    },
                    {
                        classes: '',
                        headerClasses: '',
                        dataField: 'action',
                        text: 'Acción',
                        formatter: (cell: string, row: Contract) => {
                            if (cell === 'created') {
                                return 'Creación';
                            } else {
                                return 'Actualización';
                            }
                        }
                    },
                    {
                        classes: '',
                        headerClasses: '',
                        dataField: 'user',
                        text: 'Arrendador',
                        formatter: (cell: any, row: Contract) => {
                            if (cell) {
                                return cell.first_name + ' ' + cell.last_name;
                            }
                            return '';
                        }
                    }
                    // {
                    //     dataField: '',
                    //     text: 'Acciones',
                    //     classes: 'nowrap-cell nowrap-cell-no-min',
                    //     headerClasses: 'nowrap-cell nowrap-cell-no-min',
                    //     formatter: (cell: any, row: Service) => {
                    //         return (
                    //             <div className="btn-group btn-group-sm">
                    //                 <ButtonTableEdit
                    //                     onClick={() => history.push(`contracts/${row.id}/edit`)}
                    //                 />
                    //                 {/*<ButtonTable*/}
                    //                 {/*    onClick={() =>*/}
                    //                 {/*        history.push(*/}
                    //                 {/*            `/contracts/${row.id}/contract-payments`*/}
                    //                 {/*        )*/}
                    //                 {/*    }*/}
                    //                 {/*    title="Ver pagos"*/}
                    //                 {/*    icon="bx bx-dollar"*/}
                    //                 {/*    variant="info"*/}
                    //                 {/*/>*/}
                    //                 {/*<ButtonTableDestroy onClick={() => destroy(row.id)}/>*/}
                    //             </div>
                    //         );
                    //     }
                    // }
                ]}
            />
        </DefaultModal>
    );
};

export default ModalContractHistory;
