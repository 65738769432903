import React from 'react';
import FieldSelect, { FieldSelectOption } from '../../../components/fields/FieldSelect';
import FieldNumber from '../../../components/fields/FieldNumber';
import { ContractCreateForm, ContractUpdateForm } from '../../../app/models/Contract';
import { CostCenter } from '../../../app/models/CostCenter';
import { Lessor } from '../../../app/models/Lessor';
import { Currency } from '../../../app/models/Currency';
import FieldTextArea from '../../../components/fields/FieldTextArea';
import FieldFile from '../../../components/fields/FileInput';
import FieldDate from '../../../components/fields/FieldDate';
import FieldInput from '../../../components/fields/FieldInput';
import moment from 'moment';

type Props = {
    costCenters: CostCenter[];
    lessors: Lessor[];
    currencies: Currency[];
    dataForm: ContractCreateForm | ContractUpdateForm;
    handleChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => void;
    disabled: boolean;
    errors: any;
};

const ContractDataForm = ({
    costCenters,
    lessors,
    currencies,
    dataForm,
    handleChange,
    disabled,
    errors
}: Props) => {
    //Se tuvo agregar un localDataFrom con metodo de onChange y onBlur para que el input no perdiera el foco al escribir
    const [localDataForm, setLocalDataForm] = React.useState(dataForm);
    const changeBlurred = (
        e: React.FocusEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | undefined
    ) => {
        if (e) {
            handleChange(e);
        }
    };
    const localHandleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
        setLocalDataForm({
            ...localDataForm,
            [e.target.name]: e.target.value
        });
    };

    return (
        <>
            <div className="row mb-3">
                <div className="col-md-4">
                    <FieldDate
                        label={'Fecha de inicio'}
                        name={'start_date'}
                        value={dataForm.start_date}
                        onChange={handleChange}
                        disabled={disabled}
                        errors={errors}
                    />
                </div>
                <div className="col-md-4">
                    <FieldDate
                        label={'Fecha de termino'}
                        name={'end_date'}
                        value={dataForm.end_date}
                        onChange={handleChange}
                        disabled={disabled}
                        errors={errors}
                    />
                </div>

                <div className="col-md-4">
                    <FieldDate
                        label={'Fecha de entrega'}
                        name={'remodeling_date'}
                        value={dataForm.remodeling_date}
                        onChange={handleChange}
                        disabled={disabled}
                        errors={errors}
                    />
                </div>
            </div>
            <div className="row mb-3">
                {
                    localDataForm.grace_days != null ? (<>
                                        <div className="col-md-6">
                    <FieldNumber
                        name={'grace_days'}
                        label={'Dias de gracia'}
                        value={localDataForm.grace_days}
                        onBlur={changeBlurred}
                        onChange={localHandleChange}
                        disabled={disabled}
                        errors={errors}
                        min={0}
                    />
                </div>
                                                        <div className="col-md-6">
                    <FieldDate
                        label={'Fecha de pago inicial de arriendo'}
                        name={'initial_payment_date'}
                        value={moment(dataForm.start_date)
                            .add(dataForm.grace_days, 'days')
                            .format('YYYY-MM-DD')}
                        onChange={handleChange}
                        disabled={true}
                        errors={errors}
                    />
                </div>
                                        </>
                    ) : (<>
                                    <div className="col-md-6">
                    <FieldNumber
                        name={'grace_months'}
                        label={'Meses de gracia'}
                        value={localDataForm.grace_months}
                        onBlur={changeBlurred}
                        onChange={localHandleChange}
                        disabled={disabled}
                        errors={errors}
                        min={0}
                    />
                </div>
                                    <div className="col-md-6">
                    <FieldDate
                        label={'Fecha de pago inicial de arriendo'}
                        name={'initial_payment_date'}
                        value={moment(dataForm.start_date)
                            .add(dataForm.grace_months, 'months')
                            .format('YYYY-MM-DD')}
                        onChange={handleChange}
                        disabled={true}
                        errors={errors}
                    />
                </div>
                                        </>

                    )
                }


            </div>
            <div className="row mb-3">
                <div className="col-md-6">
                    <FieldSelect
                        label="Centro de costo"
                        name="cost_center_id"
                        value={dataForm.cost_center_id}
                        onChange={handleChange}
                        errors={errors}
                        disabled={disabled}
                        options={costCenters.map(
                            (costCenter) =>
                                ({
                                    value: costCenter.id,
                                    label:
                                        costCenter.type +
                                        ' | ' +
                                        costCenter.cost_code_id +
                                        ' | ' +
                                        (costCenter.society_name === 'MELT_PIZZA'
                                            ? 'Melt Pizza'
                                            : costCenter.society_name === 'UNDER_PIZZA'
                                            ? 'Under Pizza'
                                            : costCenter.society_name
                                                  .replace(/_/g, ' ')
                                                  .replace(
                                                      /\w\S*/g,
                                                      (txt) =>
                                                          txt.charAt(0).toUpperCase() +
                                                          txt.substr(1).toLowerCase()
                                                  )) +
                                        ' | ' +
                                        costCenter.address
                                } as FieldSelectOption)
                        )}
                    />
                </div>
                <div className="col-md-6">
                    <FieldSelect
                        label="Arrendatario"
                        name="lessor_id"
                        value={dataForm.lessor_id}
                        onChange={handleChange}
                        errors={errors}
                        disabled={disabled}
                        options={lessors.map((lessor) => ({
                            value: lessor.id,
                            label: lessor.id_number + ' | ' + lessor.business_name
                        }))}
                    />
                </div>
            </div>
        </>
    );
};

export default ContractDataForm;
