import React, { useContext, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SidebarBrand from './components/SidebarBrand';
import { SidebarItem } from '../types';
import MetisMenu from 'metismenujs';
import { AuthContext } from '../contexts/auth';
import { TEMPLATE_CONFIG } from '../config';

const Sidebar = () => {
    const ref = useRef<any>(null);
    const location = useLocation();

    const { hasRole } = useContext(AuthContext);

    const sidebarItems = TEMPLATE_CONFIG.SIDEBAR;

    useEffect(() => {
        if (ref.current) {
            new MetisMenu(ref.current);
        }
    }, [ref]);

    useEffect(() => {
        const pathName = location.pathname;
        const initMenu = () => {
            new MetisMenu('#side-menu');
            const ul: any | null = document.getElementById('side-menu');
            const items = ul ? ul.getElementsByTagName('a') : [];
            for (let i = 0; i < items.length; ++i) {
                desactiveParentDropdown(items[i]);
            }
            for (let i = 0; i < items.length; ++i) {
                console.log(items[i].pathname.split('/')[1], pathName);
                if (pathName.split('/')[1] == items[i].pathname.split('/')[1]) {
                    activateParentDropdown(items[i]);
                    break;
                }
            }
        };
        initMenu();
    }, [location.pathname]);

    useEffect(() => {
        if (ref.current) {
            ref.current.recalculate();
        }
    });

    function scrollElement(item: any) {
        if (item && ref) {
            const currentPosition = item.offsetTop;
            if (currentPosition > window.innerHeight) {
                ref.current.getScrollElement().scrollTop = currentPosition - 300;
            }
        }
    }

    function activateParentDropdown(item: any) {
        item.classList.add('active');
        const parent = item.parentElement;
        const parent2El = parent.childNodes[1];
        if (parent2El && parent2El.id !== 'side-menu') {
            parent2El.classList.add('mm-show');
        }

        if (parent) {
            parent.classList.add('mm-active');
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add('mm-show'); // ul tag

                const parent3 = parent2.parentElement; // li tag

                if (parent3) {
                    parent3.classList.add('mm-active'); // li
                    parent3.childNodes[0].classList.add('mm-active'); //a
                    const parent4 = parent3.parentElement; // ul
                    if (parent4) {
                        parent4.classList.add('mm-show'); // ul
                        const parent5 = parent4.parentElement;
                        if (parent5) {
                            parent5.classList.add('mm-show'); // li
                            parent5.childNodes[0].classList.add('mm-active'); // a tag
                        }
                    }
                }
            }
            scrollElement(item);
            return false;
        }
        scrollElement(item);
        return false;
    }

    function desactiveParentDropdown(item: any) {
        // Quita todas las clases agregadas con "add"
        item.classList.remove('active');
        const parent = item.parentElement;
        const parent2El = parent.childNodes[1];
        if (parent2El && parent2El.id !== 'side-menu') {
            parent2El.classList.remove('mm-show'); // Quita la clase "mm-show"
        }

        if (parent) {
            parent.classList.remove('mm-active'); // Quita la clase "mm-active"
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.remove('mm-show'); // Quita la clase "mm-show"

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.remove('mm-active'); // Quita la clase "mm-active"
                    parent3.childNodes[0].classList.remove('mm-active'); // Quita la clase "mm-active"
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.remove('mm-show'); // Quita la clase "mm-show"
                        const parent5 = parent4.parentElement;
                        if (parent5) {
                            parent5.classList.remove('mm-show'); // Quita la clase "mm-show"
                            parent5.childNodes[0].classList.remove('mm-active'); // Quita la clase "mm-active"
                        }
                    }
                }
            }
            return false;
        }
        return false;
    }

    const processSidebarItems = (item: SidebarItem) => {
        switch (item.type) {
            case 'title':
                return <li className="menu-title">{item.name}</li>;
            case 'item':
                return (
                    <li>
                        <Link to={item.path ?? '#'}>
                            <i className={item.icon ?? ''} />
                            <span>{item.name}</span>
                        </Link>
                    </li>
                );
            case 'collapse':
                return (
                    <li>
                        <Link to={item.path ?? '#'} className="has-arrow">
                            <i className={item.icon ?? ''} />
                            <span>{item.name}</span>
                        </Link>
                        <ul className="sub-menu">
                            {item.children?.map((item, index) => (
                                <li key={index}>
                                    <Link to={item.path ?? '#'}>{item.name}</Link>
                                </li>
                            ))}
                        </ul>
                    </li>
                );
            default:
                return null;
        }
    };

    return (
        <div className="vertical-menu">
            <SidebarBrand />
            <div className="h-100">
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        {sidebarItems.map((item: SidebarItem, index: number) => {
                            return (
                                <React.Fragment key={index}>
                                    {processSidebarItems(item)}
                                </React.Fragment>
                            );
                        })}
                    </ul>
                </div>
            </div>
            <div className="sidebar-background"></div>
        </div>
    );
};

export default Sidebar;
