// import React from 'react';
//
// const DefaultTable = () => {
//     return (
//         <>
//             <h1>Default Table</h1>
//         </>
//     );
// };
//
// export default DefaultTable;

import React, { Fragment } from 'react';
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import NoRecords from '../../template/components/ui/NoRecords';
import Loading from '../../template/components/ui/Loading';

type TableProps = {
    data: any[];
    columns: any[];
    tableLoaded?: boolean;
    pagination?: boolean;
    paginationSize?: number;
    order?: boolean;
    onTableChange?: any;
    hover?: boolean;
    expandRow?: any;
};

const DefaultTable = ({
    data,
    columns,
    tableLoaded = true,
    pagination = true,
    paginationSize = 10,
    order = false,
    onTableChange = null,
    hover = true,
    expandRow = undefined
}: TableProps) => {
    // useEffect(() => {
    //     if (order) {
    //         objects.sort((a, b) => (a.name > b.name) ? 1 : -1)
    //     }
    // }, [objects])

    const customTotal = (from: number, to: number, size: number) => (
        //       <span className="react-bootstrap-table-pagination-total">
        //   Showing { from } to { to } of { size } Results
        // </span>
        <span> </span>
    );

    const optionsPagination = {
        paginationSize: paginationSize,
        pageStartIndex: 1,
        // alwaysShowAllBtns: true, // Always show next and previous button
        // withFirstAndLast: false, // Hide the going to First and Last page button
        // hideSizePerPage: true, // Hide the sizePerPage dropdown always
        // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
        // firstPageText: 'First',
        // prePageText: 'Back',
        // nextPageText: 'Next',
        // lastPageText: 'Last',
        // nextPageTitle: 'First page',
        // prePageTitle: 'Pre page',
        // firstPageTitle: 'Next page',
        // lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [
            {
                text: '10',
                value: 10
            },
            {
                text: '25',
                value: 25
            },
            {
                text: '50',
                value: 50
            },
            {
                text: 'Todos',
                value: data.length
            }
        ] // A numeric array is also available. the purpose of above example is custom the text
    };
    return !tableLoaded ? (
        <Fragment>
            {
                data.length === 0 ? (
                    <NoRecords />
                ) : (
                    // <div className="table-responsive">
                    <BootstrapTable
                        remote={{ cellEdit: true }}
                        // wrapperClasses="table-responsive"
                        bootstrap4
                        keyField="id"
                        data={data}
                        columns={columns}
                        pagination={pagination ? paginationFactory(optionsPagination) : undefined}
                        // filter={filterFactory}
                        onTableChange={onTableChange ? onTableChange : null}
                        bordered={true}
                        hover={hover}
                        classes="table table align-middle table-nowrap table-check"
                        headerClasses="table-header-45"
                        headerWrapperClasses="table-light"
                        expandRow={expandRow}
                    />
                )
                // </div>
            }
        </Fragment>
    ) : (
        <Loading height={300} />
    );
};

export default DefaultTable;
