import React, { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '../../../template/layouts/components/Breadcrumbs';
import Loading from '../../../template/components/ui/Loading';
import { ContractUpdateForm, initialContractUpdateForm } from '../../../app/models/Contract';
import { CostCenter } from '../../../app/models/CostCenter';
import { Currency } from '../../../app/models/Currency';
import { Lessor } from '../../../app/models/Lessor';
import useContractsService from '../../../hooks/services/useContractsService';
import DefaultCard from '../../../components/default/DefaultCard';
import ContractDataForm from '../components/ContractDataForm';
import RuleRentDataForm from '../components/RuleRentDataForm';
import RuleMonthlySaleDataForm from '../components/RuleMonthlySaleDataForm';
import WarrantyTypeDataForm from '../components/WarrantyTypeDataForm';
import OtherExpenseDataForm from '../components/OtherExpenseDataForm';
import ContractServiceDataForm from '../components/ContractServiceDataForm';
import ExitClauseDataForm from '../components/ExitClauseDataForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { toast } from 'react-toastify';
import ModalContractHistory from '../components/ModalContractHistory';
import ContractDocumentsDataForm from '../components/ContractDocumentDataForm';
import { ContractEndpoint } from '../../../app/services/APIConnection';
import { ServiceResponse } from '../../../packages/generic-fetch/types';
import { LOCAL_STORAGE } from '../../../template/config';

type Params = {
    contract_id: string;
};

const ContractEditPage = () => {
    const params = useParams<Params>();

    const breadcrumbs = [
        {
            name: 'Contratos',
            url: '/contracts',
            isActive: false
        },
        {
            name: 'Editar contrato',
            url: `/contracts/${params.contract_id}/edit`,
            isActive: true
        }
    ];
    const [showingModalDocuments, setShowingModalDocuments] = useState<boolean>(false);
    const { fetchingEditContract, editContract, fetchingUpdateContract, updateContract } = useContractsService();
    const [errors, setErrors] = useState<object>({});
    const [costCenters, setCostCenters] = useState<CostCenter[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [lessors, setLessors] = useState<Lessor[]>([]);
    const [services, setServices] = useState<any[]>([]);
    const [typeMonthlySales, setTypeMonthlySales] = useState<any[]>([]);
    const [banks, setBanks] = useState<any[]>([]);
    const [dataForm, setDataForm] = useState<ContractUpdateForm>(initialContractUpdateForm);
    const [fetchingStoreDocumentContract, setFetchingStoreDocumentContract] =
        useState<boolean>(false);
    useEffect(() => {
        edit();
    }, []);

    const [reformatNumber, setReformatNumber] = useState<number>(0);

    const edit = () => {
        editContract(parseInt(params.contract_id), {
            onSuccess: (response) => {
                setCurrencies(response.data.currencies);
                setLessors(response.data.lessors);
                setServices(response.data.services);
                setDataForm(response.data.contract);
                setCostCenters(response.data.cost_centers);
                setBanks(response.data.banks);
                setTypeMonthlySales(response.data.type_monthly_sales);
            }
        }).then();
    };

    const update = () => {
        if (dataForm.rule_rents) {
            dataForm.rule_rents = dataForm.rule_rents.map((item, index) => {
                item.amount = String(item.amount).replaceAll('.', '').replaceAll(',', '.');
                return item;
            });
        }

        if (dataForm.rule_monthly_sales) {
            dataForm.rule_monthly_sales = dataForm.rule_monthly_sales.map((item, index) => {
                item.amount = String(item.amount).replaceAll('.', '').replaceAll(',', '.');
                item.monthly_rent_percentage = String(item.monthly_rent_percentage)
                    .replaceAll('.', '')
                    .replaceAll(',', '.');
                return item;
            });
        }

        if (dataForm.warranty_types) {
            dataForm.warranty_types = dataForm.warranty_types.map((item, index) => {
                if (item.amount) {
                    item.amount = String(item.amount).replaceAll('.', '').replaceAll(',', '.');
                }
                return item;
            });
        }

        if (dataForm.other_expenses) {
            dataForm.other_expenses = dataForm.other_expenses.map((item, index) => {
                if (item.amount) {
                    item.amount = String(item.amount).replaceAll('.', '').replaceAll(',', '.');
                }
                return item;
            });
        }

        if (dataForm.exit_clauses) {
            dataForm.exit_clauses = dataForm.exit_clauses.map((item, index) => {
                if (item.amount) {
                    item.amount = String(item.amount).replaceAll('.', '').replaceAll(',', '.');
                }
                return item;
            });
        }

        updateContract(Number(params.contract_id), dataForm, {
            onSuccess: (response) => {
                setFetchingStoreDocumentContract(true);
                const formData = new FormData();
                if (dataForm.document_contracts) {
                    dataForm.document_contracts.forEach((document) => {
                        if (document.file) {
                            formData.append('file[]', document.file as Blob);
                        }
                    });
                } else {
                    toast.success('Contrato actualizado correctamente');
                    edit();
                    return;
                }

                try {
                    fetch(ContractEndpoint.updateDocuments(response.data), {
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                LOCAL_STORAGE.AUTH_TOKEN
                            )}`
                        },
                        body: formData
                    })
                        .then(async (response) => {
                            const result: ServiceResponse = await response.json();

                            if (result.status == 'success') {
                                toast.success('Contrato actualizado correctamente');
                                edit();
                            }

                            if (result.status == 'error') {
                                toast.error('Error al subir las imagenes');
                            }

                            if (result.status == 'fields_validation') {
                                setErrors(result.data.errors);
                            }
                        })
                        .then(() => {
                            setFetchingStoreDocumentContract(false);
                        });
                } catch (e) {
                    setFetchingStoreDocumentContract(false);
                }
            },
            onError: (response) => {
                setReformatNumber(reformatNumber + 1);
                toast.error(response.message);
            },
            onFieldError: (response) => {
                setReformatNumber(reformatNumber + 1);
                setErrors(response.data.errors);
            }
        });
    };

    const handleChange = (
        event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
        setDataForm({
            ...dataForm,
            [event.target.name]: event.target.value
        });
    };

    const handleArrayChange = (nameAttribute: string, updatedArray: any[]) => {
        setDataForm({
            ...dataForm,
            [nameAttribute]: updatedArray
        });
    };

    return (
        <>
            <Breadcrumbs pageSection="Gestión de Contratos" breadcrumbs={breadcrumbs} />

            {fetchingEditContract ? (
                <Loading height={300} />
            ) : (
                <>
                    <div className="row">
                        <div className="col-md-12">
                            <DefaultCard
                                title="DATOS DEL CONTRATO"
                                button={
                                    <button
                                        onClick={() => setShowingModalDocuments(true)}
                                        title="Historico"
                                        className="btn btn-sm btn-primary"
                                    >
                                        <i className="fas fa-history"></i>
                                    </button>
                                }
                            >
                                {/*<DefaultToolBar*/}
                                {/*    right={*/}
                                {/*        <button*/}
                                {/*            onClick={() => setShowingModalDocuments(true)}*/}
                                {/*            title="Historico"*/}
                                {/*            className="btn btn-sm btn-primary"*/}
                                {/*        >*/}
                                {/*            Historico*/}
                                {/*        </button>*/}
                                {/*    }*/}
                                {/*/>*/}
                                <ContractDataForm
                                    costCenters={costCenters}
                                    lessors={lessors}
                                    currencies={currencies}
                                    dataForm={dataForm}
                                    handleChange={handleChange}
                                    disabled={fetchingUpdateContract}
                                    errors={errors}
                                />
                            </DefaultCard>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <DefaultCard title="ACTUALIZACIÓN DE ARRIENDO POR PERIODO ">
                                <RuleRentDataForm
                                    onUpdateRuleRents={(updatedRuleRents) =>
                                        handleArrayChange('rule_rents', updatedRuleRents)
                                    }
                                    currencies={currencies}
                                    ruleRents={dataForm.rule_rents ?? []}
                                    disabled={fetchingUpdateContract}
                                    errors={errors}
                                    reformatNumber={reformatNumber}

                                />
                            </DefaultCard>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <DefaultCard title="ACTUALIZACIÓN DE ARRIENDO VARIABLE POR PORCENTAJE DE VENTA MENSUAL ">
                                <RuleMonthlySaleDataForm
                                    onUpdateRuleMonthlySales={(onUpdateRuleMonthlySales) =>
                                        handleArrayChange(
                                            'rule_monthly_sales',
                                            onUpdateRuleMonthlySales
                                        )
                                    }
                                    currencies={currencies}
                                    typeMonthlySales={typeMonthlySales ?? []}
                                    ruleMonthlySales={dataForm.rule_monthly_sales ?? []}
                                    disabled={fetchingUpdateContract}
                                    errors={errors}
                                    reformatNumber={reformatNumber}
                                />
                            </DefaultCard>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <DefaultCard title="TIPOS DE GARANTÍA">
                                <WarrantyTypeDataForm
                                    onUpdateWarrantyTypes={(updateWarrantyTypes) =>
                                        handleArrayChange('warranty_types', updateWarrantyTypes)
                                    }
                                    currencies={currencies}
                                    banks={banks}
                                    warrantyTypes={dataForm.warranty_types ?? []}
                                    disabled={fetchingUpdateContract}
                                    errors={errors}
                                    reformatNumber={reformatNumber}
                                />
                            </DefaultCard>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <DefaultCard title="OTROS GASTOS">
                                <OtherExpenseDataForm
                                    onUpdateOtherExpenses={(updateOtherExpenses) =>
                                        handleArrayChange('other_expenses', updateOtherExpenses)
                                    }
                                    currencies={currencies}
                                    otherExpenses={dataForm.other_expenses ?? []}
                                    disabled={fetchingUpdateContract}
                                    errors={errors}
                                    reformatNumber={reformatNumber}

                                />
                            </DefaultCard>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <DefaultCard title="GASTOS SERVICIOS">
                                <ContractServiceDataForm
                                    onUpdateContractServices={(updateContractServices) =>
                                        handleArrayChange(
                                            'contract_services',
                                            updateContractServices
                                        )
                                    }
                                    contractServices={dataForm.contract_services ?? []}
                                    disabled={fetchingUpdateContract}
                                    errors={errors}
                                />
                            </DefaultCard>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <DefaultCard title="CLAUSULAS DE SALIDA">
                                <ExitClauseDataForm
                                    onUpdateExitClauses={(updateExitClauses) =>
                                        handleArrayChange('exit_clauses', updateExitClauses)
                                    }
                                    currencies={currencies}
                                    exitClauses={dataForm.exit_clauses ?? []}
                                    disabled={fetchingUpdateContract}
                                    errors={errors}
                                    reformatNumber={reformatNumber}
                                />
                            </DefaultCard>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <DefaultCard title="DOCUMENTOS">
                                <ContractDocumentsDataForm
                                    contractDocuments={dataForm.document_contracts ?? []}
                                    disabled={false}
                                    contractId={-1}
                                    errors={errors}
                                    setContractDocuments={(updateDocuments) =>
                                        handleArrayChange('document_contracts', updateDocuments)
                                    }
                                />
                            </DefaultCard>
                        </div>
                    </div>
                    <div>
                        <div className="row mt-auto justify-content-end mb-2">
                            <div className="col-auto">
                                <ButtonSaveForm onClick={update} loading={fetchingUpdateContract} />
                            </div>
                        </div>
                    </div>

                    {showingModalDocuments && (
                        <ModalContractHistory
                            contractId={parseInt(params.contract_id)}
                            showingView={showingModalDocuments}
                            closeView={() => setShowingModalDocuments(false)}
                            onSuccess={(response) => {
                                toast.success(response.message ?? 'Pago creado con éxito');
                                setShowingModalDocuments(false);
                            }}
                            onError={(response) => {
                                toast.error(response.message ?? 'Error al crear el pago');
                            }}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default ContractEditPage;
