import React, { useEffect, useState } from 'react';
import DefaultCard from '../../../components/default/DefaultCard';
import { useHistory } from 'react-router-dom';
import Breadcrumbs from '../../../template/layouts/components/Breadcrumbs';
import DefaultToolBar from '../../../components/default/DefaultToolBar';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import ModalCreateContract from '../components/ModalCreateContract';
import { toast } from 'react-toastify';
import { Service } from '../../../app/models/Service';
import ButtonTableEdit from '../../../components/buttons/ButtonTableEdit';
import DefaultTable from '../../../components/default/DefaultTable';
import useContractsService from '../../../hooks/services/useContractsService';
import { Contract } from '../../../app/models/Contract';
import useSweetAlert from '../../../hooks/useSweetAlert';
import ButtonTable from '../../../components/buttons/ButtonTable';
import moment from 'moment';
import { RuleRent } from '../../../app/models/RuleRent';
import { RuleMonthlySale } from '../../../app/models/RuleMonthlySale';
import { CostCenter } from '../../../app/models/CostCenter';
import FieldSelect, { FieldSelectOption } from '../../../components/fields/FieldSelect';
import SelectTwo, { SelectTwoMapperOptions } from '../../../components/fields/SelectTwo';
import useCostCentersService from '../../../hooks/services/useCostCentersService';
import FieldNumber from '../../../components/fields/FieldNumber';
import { formatAmount } from '../utils';
import {Lessor} from "../../../app/models/Lessor";

const ContractsPage = () => {
    const history = useHistory();

    const breadcrumbs = [
        {
            name: 'Contratos',
            url: '/contracts',
            isActive: true
        }
    ];

    const {
        fetchingGetContracts,
        getContracts,
        deleteContract,
        getContractsPDF,
        getContractsExcel
    } = useContractsService();

    const { fetchingGetCostCenters, getCostCenters } = useCostCentersService();

    const [costCenters, setCostCenters] = useState<CostCenter[]>([]);
    const [contracts, setContracts] = useState<Contract[]>([]);
    const [month, setMonth] = useState<string>(moment().format('YYYY-MM'));
    const [showingCreateView, setShowingCreateView] = useState(false);
    const [currency, setCurrency] = useState<string>('UF');
    const [costCenterSelected, setCostCenterSelectedSelected] = useState<any[]>([]);
    const [year, setYear] = useState<string>(moment().format('YYYY'));

    useEffect(() => {
        init();
        getCostCenters({
            onSuccess: (response) => {
                setCostCenters(response.data.cost_centers);
            }
        }).then();
    }, []);

    const init = () => {
        const costCenterArray = costCenterSelected.map((costCenter) => {
            return costCenter.label;
        });
        getContracts(currency, month, year, costCenterArray, {
            onSuccess: (response) => {
                setContracts(response.data.contracts);
            }
        }).then();
    };

    const destroy = (id: number) => {
        const { requestConfirmation } = useSweetAlert();
        requestConfirmation({
            title: '¿Estás seguro?',
            text: 'No podrás revertir esta acción',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
            onConfirm: () => {
                deleteContract(id, {
                    onSuccess: (response) => {
                        toast.success(response.message ?? 'Proveedor eliminado con éxito');
                        init();
                    }
                }).then();
            }
        });
    };

    const downloadPDF = () => {
        const costCenterArray = costCenterSelected.map((costCenter) => {
            return costCenter.label;
        });
        getContractsPDF(currency, month, year, costCenterArray, {
            onSuccess: (response) => {
                const blob = response.data;
                const a = document.createElement('a');
                const url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = 'contratos.pdf';
                a.click();
                window.URL.revokeObjectURL(url);
            }
        }).then();
    };

    const downloadExcel = () => {
        const costCenterArray = costCenterSelected.map((costCenter) => {
            return costCenter.label;
        });
        getContractsExcel(currency, month, year, costCenterArray, {
            onSuccess: (response) => {
                const blob = response.data;
                const a = document.createElement('a');
                const url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = 'contratos.xlsx';
                a.click();
                window.URL.revokeObjectURL(url);
            }
        }).then();
    };

    return (
        <>
            <Breadcrumbs pageSection="Gestión de Contratos" breadcrumbs={breadcrumbs} />
            <DefaultCard>
                <DefaultToolBar
                    left={
                        <>
                            <div className="row">
                                <div className="col mt-4">
                                    <ButtonCreate
                                        onClick={() => history.push(`contracts/create`)}
                                        title="Nuevo Contrato"
                                        className={'btn btn-primary me-4'}
                                    />
                                    <button
                                        type="button"
                                        className={`waves-effect btn btn-smm btn-danger me-4`}
                                        onClick={() => downloadPDF()}
                                    >
                                        <i className="fa fa-file-pdf" /> Descargar PDF
                                    </button>
                                    <button
                                        type="button"
                                        className={`waves-effect btn btn-smm btn-success`}
                                        onClick={() => downloadExcel()}
                                    >
                                        <i className="fa fa-file-excel" /> Descargar Excel
                                    </button>
                                </div>

                                <div className="col-md-3">
                                    <label className="mb-1" htmlFor="end_date">
                                        Centro de costos
                                    </label>
                                    <SelectTwo
                                        name="cost_center_id"
                                        inputValue={costCenterSelected}
                                        onChange={(selectedOptions) => {
                                            setCostCenterSelectedSelected(selectedOptions);
                                        }}
                                        options={SelectTwoMapperOptions(costCenters, 'id', [
                                            'cost_code_id'
                                        ])}
                                        isMulti={true}
                                    />
                                </div>
                                <div className="col-md-2">
                                    <label className="mb-1" htmlFor="year">
                                        Contratos del año
                                    </label>

                                    <div className="col-auto">
                                        <FieldNumber
                                            name={'year'}
                                            value={Number(year)}
                                            onChange={(e) => setYear(e.target.value)}
                                        />
                                    </div>
                                </div>
                                {/*<div className="col-auto">*/}
                                {/*    <label className="mb-1" htmlFor="end_date">*/}
                                {/*        Moneda*/}
                                {/*    </label>*/}
                                {/*    <FieldSelect*/}
                                {/*        name={'currency'}*/}
                                {/*        value={currency}*/}
                                {/*        onChange={(e) => setCurrency(e.target.value)}*/}
                                {/*        options={[*/}
                                {/*            { value: 'UF', label: 'UF' },*/}
                                {/*            { value: 'CLP', label: 'CLP' }*/}
                                {/*        ]}*/}
                                {/*    />*/}
                                {/*</div>*/}
                                <div className="col-auto mt-4">
                                    <button
                                        type="button"
                                        className={`waves-effect btn btn-primary btn-block`}
                                        onClick={init}
                                    >
                                        <i className="fa fa-filter" /> Filtrar
                                    </button>
                                </div>
                                {/*<div className="col-auto mt-3">*/}
                                {/*    <label className="form-label">*/}
                                {/*        Filtrar por mes pago*/}
                                {/*    </label>*/}
                                {/*</div>*/}
                                {/*<div className="col-auto">*/}
                                {/*    <input*/}
                                {/*        type="month"*/}
                                {/*        className="form-control"*/}
                                {/*        value={month}*/}
                                {/*        onChange={(e) => setMonth(e.target.value)}*/}
                                {/*    />*/}
                                {/*</div>*/}
                            </div>
                        </>
                    }
                />

                <DefaultTable
                    data={contracts}
                    tableLoaded={fetchingGetContracts}
                    columns={[
                        {
                            classes: '',
                            headerClasses: '',
                            dataField: 'cost_center',
                            text: 'Centro de costo',
                            formatter: (cell: CostCenter, row: Contract) => {
                                if (!cell) {
                                    return '';
                                }
                                return cell.cost_code_id + ' - ' + cell.name;
                            }
                        },
                                                {
                            classes: '',
                            headerClasses: '',
                            dataField: 'lessor',
                            text: 'Arrendador',
                            formatter: (cell: Lessor, row: Contract) => {
                                if (!cell) {
                                    return '';
                                }
                                return cell.id_number +' | '+ cell.business_name;
                            }
                        },
                        {
                            classes: '',
                            headerClasses: '',
                            dataField: 'start_date',
                            text: 'Fecha De inicio',
                            formatter: (cell: string, row: Contract) => {
                                return moment(cell).format('DD-MM-YYYY');
                            }
                        },
                        {
                            classes: '',
                            headerClasses: '',
                            dataField: 'end_date',
                            text: 'Fecha de término',
                            formatter: (cell: string, row: Contract) => {
                                return moment(cell).format('DD-MM-YYYY');
                            }
                        },
                        {
                            classes: 'text-end',
                            headerClasses: '',
                            dataField: 'active_rule_rent_amount',
                            text: 'CANON Vigente',
                            formatter: (cell: any, row: any) => {
                                if (!cell) {
                                    return '';
                                }
                                const amount = cell ?? 0;
                                return formatAmount(amount, row.active_rule_rent_currency);
                            }
                        },
                        {
                            classes: 'text-end',
                            headerClasses: '',
                            dataField: 'active_rule_monthly_sale_percentage',
                            text: 'Porcentaje Variable',
                            formatter: (cell: any, row: Contract) => {
                                if (!cell) {
                                    return 'N/A';
                                }
                                return `${cell}%`;
                            }
                        },
                        // {
                        //     classes: 'text-end',
                        //     headerClasses: '',
                        //     dataField: 'active_rule_monthly_sale_amount',
                        //     text: 'Variable',
                        //     formatter: (cell: any, row: any) => {
                        //         if (!cell) {
                        //             return '';
                        //         }
                        //         const amount = cell ?? 0;
                        //         //
                        //         // if (!row.monthly_sales_rule_is_activated) {
                        //         //     return '';
                        //         // }
                        //         return formatAmount(amount, row.active_rule_monthly_sale_currency);
                        //     }
                        // },
                        {
                            classes: '',
                            headerClasses: '',
                            dataField: 'active_rule_monthly_type',
                            text: 'Tipo de Venta',
                            formatter: (cell: string, row: Contract) => {
                                if (cell === 'total_amount') {
                                    return 'Venta Neta total';
                                }
                                if (cell === 'total_amount_without_aggregator') {
                                    return 'Venta Neta Sin Agregadores';
                                }
                                return '';
                            }
                        },
                        // {
                        //     classes: 'text-end',
                        //     headerClasses: '',
                        //     dataField: 'total_sale',
                        //     text: 'Total Ventas',
                        //     formatter: (cell: string, row: Contract) => {
                        //         if (!cell) {
                        //             return '';
                        //         }
                        //         const amount = cell ?? 0;
                        //         return `${
                        //             currency === 'UF'
                        //                 ? Intl.NumberFormat('DE-de').format(Number(amount)) + ' UF'
                        //                 : '$ ' + Intl.NumberFormat('DE-de').format(Number(amount))
                        //         }`;
                        //     }
                        // },
                        {
                            classes: '',
                            headerClasses: '',
                            dataField: '',
                            text: 'Fecha de Vencimiento Boleta Garantía',
                            formatter: (cell: RuleRent, row: Contract) => {
                                const warrantyType = row.warranty_types?.find(
                                    (w) => w.type === 'boleta'
                                );
                                if (warrantyType) {
                                    return moment(warrantyType.expiration_date).format(
                                        'DD-MM-YYYY'
                                    );
                                }
                                return 'N/A';
                            }
                        },
                        {
                            classes: '',
                            headerClasses: '',
                            dataField: '',
                            text: 'Banco Boleta Garantía',
                            formatter: (cell: RuleRent, row: Contract) => {
                                const warrantyType = row.warranty_types?.find(
                                    (w) => w.type === 'boleta'
                                );
                                if (warrantyType) {
                                    return warrantyType.bank.name;
                                }
                                return 'N/A';
                            }
                        },
                        {
                            dataField: '',
                            text: 'Acciones',
                            classes: 'nowrap-cell nowrap-cell-no-min',
                            headerClasses: 'nowrap-cell nowrap-cell-no-min',
                            formatter: (cell: any, row: Service) => {
                                return (
                                    <div className="btn-group btn-group-sm">
                                        <ButtonTableEdit
                                            onClick={() => history.push(`contracts/${row.id}/edit`)}
                                        />
                                        <ButtonTable
                                            onClick={() =>
                                                history.push(`/contracts/${row.id}/payments`)
                                            }
                                            title="Ver pagos"
                                            icon="bx bx-dollar"
                                            variant="info"
                                        />
                                        {/*<ButtonTableDestroy onClick={() => destroy(row.id)}/>*/}
                                    </div>
                                );
                            }
                        }
                    ]}
                />

                {showingCreateView && (
                    <ModalCreateContract
                        showingView={showingCreateView}
                        closeView={() => setShowingCreateView(false)}
                        onSuccess={(response) => {
                            setShowingCreateView(false);
                            toast.success(response.message ?? 'Contrato creado con éxito');
                            init();
                        }}
                    />
                )}
            </DefaultCard>
        </>
    );
};

export default ContractsPage;
